import { useEffect, useMemo, useRef } from 'react';

export function usePersistentCallback<T extends (...args: any[]) => any>(
  callback: T | undefined,
  deps: any[],
): T {
  const persistedCallback = useRef(callback);
  useEffect(() => {
    persistedCallback.current = callback;
  });

  const persistedFunction = useMemo(
    () => ((...args) => persistedCallback.current?.(...args)) as T,
    [deps],
  );

  return persistedFunction;
}
