import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { configure } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Router, Switch } from 'react-router';

import { Defaults, HtmlText, TextEx } from './components/common';
import { MessageBox } from './components/controls/Dialog';
const PageHome = lazy(() => import('./components/home/PageHome'));
const PagePlatform = lazy(() => import('./components/home/PagePlatform'));
const PageBot = lazy(() => import('./components/home/PageBot'));
const PageSkyPay = lazy(() => import('./components/home/PageSkyPay'));
const PageTraining = lazy(() => import('./components/home/PageTraining'));
const PageFAQ = lazy(() => import('./components/home/PageFAQ'));
const PagePartner = lazy(() => import('./components/home/PagePartner'));
const PageContacts = lazy(() => import('./components/home/PageContacts'));
const PageBanned = lazy(() => import('./components/maintenance/PageBanned'));
const PageFastDeal = lazy(() => import('./components/fastdeal/PageFastDeal'));
const PageWhiteHackerPost = lazy(() => import('./components/home/PageWhiteHackerPost'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
import './index.css';
import './components/home/css/home.css';
import './components/home/css/main.min.css';

import { SpinnerCircular } from 'spinners-react';

import Layout from './components/Layout';
import PageLogin from './components/login/PageLogin';
import { notificationUpdater } from './components/topBar/UserNotifications';
import { PRIVACY_LINK } from './config/constants';
import history, { setPage } from './config/history';
import { tt } from './config/i18n';
import { logout, setRefreshInProgress } from './helpers/authHeader';
import { listenMessages, Message } from './helpers/notifications';
import { APP_VERSION } from './helpers/settings';
import CurrenciesService from './services/CurrenciesService';
import { AuthStore, CurrencyStore, RegionsStore } from './store';
import { deletePersistedData } from './store/TableStore';
import { handleCodedata } from './utils/axiosSetup';
import {
  createWebsocketConnect,
  disconnectCurrentWSConnection,
  WS_SIGNALS,
} from './utils/websocket';

configure({ enforceActions: 'observed' });

let isTabActive = document.visibilityState === 'visible';

export { isTabActive };

const theme = createTheme({
  typography: {
    fontFamily: Defaults.fontFamily,
  },
});

const queryClient = new QueryClient();

export default observer(() => {
  const [error, setError] = useState<string>();
  const [info, setInfo] = useState<string>();

  const { getFiatCurrencies, fiatCurrencyHasChangedOnce } = CurrencyStore;
  const { defineRegion } = RegionsStore;
  const {
    isAuthorized,
    token,
    showLoginDialog,
    email,
    tokenListeners,
    openLoginDialog,
    closeLoginDialog,
    clearTokenListeners,
    setAuthorization,
  } = AuthStore;

  useEffect(() => {
    if (fiatCurrencyHasChangedOnce || !isAuthorized) {
      defineRegion();
    }
  }, [fiatCurrencyHasChangedOnce]);

  const handleVisibilityChange = useCallback(() => {
    isTabActive = document.visibilityState === 'visible';
  }, []);

  useEffect(() => {
    getFiatCurrencies();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return listenMessages(ListenMessages);
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      const WS_HANDLERS = {
        [WS_SIGNALS.UPDATE_DEAL]: (notification: any) =>
          notificationUpdater([notification]),
        [WS_SIGNALS.UPDATE_CODEDATA]: (codedata: any) =>
          handleCodedata(JSON.parse(codedata)),
        [WS_SIGNALS.UPDATE_RATES]: (rates: any) => CurrenciesService.setRates(rates),
      };

      createWebsocketConnect(WS_HANDLERS);
    } else {
      deletePersistedData();
      disconnectCurrentWSConnection();
    }

    return () => {
      setRefreshInProgress(false);
    };
  }, [isAuthorized]);

  const ListenMessages = (message: Message) => {
    switch (message.type) {
      case 'error':
        setError(message.text);
        break;
      case 'login':
        openLoginDialog(message.text);
        break;
      default:
        setInfo(message.text);
    }
  };

  const onLoginSuccess = (action) =>
    setPage(`wallet${action === 'register' ? '/start' : ''}`);

  const onLoginToken = (token: string): boolean => {
    let result = true;
    for (const tokenListener of tokenListeners) {
      if (!tokenListener(token)) {
        result = false;
      }
    }
    clearTokenListeners();
    if (!result) {
      closeLoginDialog();
    }
    return result;
  };

  const toPrivacyRedirect = () => {
    window.location.replace(PRIVACY_LINK);
    return '';
  };

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MuiThemeProvider theme={theme}>
          <Router history={history}>
            <Suspense
              fallback={
                <SpinnerCircular
                  color={Defaults.mainColor}
                  size={400}
                  className="page-spinner"
                />
              }>
              <Switch>
                <Route exact path={'/'} component={PageHome} />
                <Route exact path={'/platform'} component={PagePlatform} />
                <Route exact path={'/phone'} component={PageBot} />
                <Route exact path={'/sky-pay'} component={PageSkyPay} />
                <Route exact path={'/training'} component={PageTraining} />
                <Route exact path={'/faq'} component={PageFAQ} />
                <Route exact path={'/partner'} component={PagePartner} />
                <Route exact path={'/contacts'} component={PageContacts} />
                <Route exact path={'/banned'} component={PageBanned} />
                <Route exact path={'/fast-deal'} component={PageFastDeal} />
                <Route exact path={'/privacy'} render={toPrivacyRedirect} />
                <Route
                  exact
                  path={'/news/white-hack-support-program-help'}
                  component={PageWhiteHackerPost}
                />
                <Route
                  exact
                  path={'/news/white-hack-support-program-help'}
                  component={PageWhiteHackerPost}
                />
                <Route
                  exact
                  path={'/news/white-hack-support-program_help'}
                  component={PageWhiteHackerPost}
                />
                <Route
                  exact
                  path={'/news/white-hack-support-program__help'}
                  component={PageWhiteHackerPost}
                />
                <Route component={token ? Layout : PageNotFound} />
              </Switch>
            </Suspense>
          </Router>
          <Version />
          <PageLogin
            open={showLoginDialog}
            email={email}
            onClose={closeLoginDialog}
            onLogin={onLoginToken}
            onSuccess={onLoginSuccess}
          />
          <ErrorBox open={!!error} error={error} onClose={() => setError(undefined)} />
          <InfoBox open={!!info} info={info} onClose={() => setInfo(undefined)} />
        </MuiThemeProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
});

const Version = () => (
  <div
    style={{
      position: 'fixed',
      right: '1vh',
      top: '97vh',
      fontFamily: Defaults.fontFamily,
      fontSize: '1vh',
      zIndex: 999,
    }}>
    {APP_VERSION}
  </div>
);

const ErrorBox = ({
  open,
  error,
  onClose,
}: {
  open: boolean;
  error?: string;
  onClose: () => void;
}) => {
  if (!error) {
    return <></>;
  }

  const translated = tt('errors', error, 'unknown');

  return (
    <MessageBox open={open} caption={'common.error'} onClose={onClose}>
      <TextEx size={'1.125rem'}>{translated === 'unknown' ? error : translated}</TextEx>
    </MessageBox>
  );
};

const InfoBox = ({
  open,
  info,
  onClose,
}: {
  open: boolean;
  info?: string;
  onClose: () => void;
}) => {
  return info ? (
    <MessageBox open={open} onClose={onClose}>
      <HtmlText k={info} size={'1.125rem'} />
    </MessageBox>
  ) : (
    <></>
  );
};
