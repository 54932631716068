import _ from 'lodash';

import { IDealsFilter } from '../components/deals';
import { DealStatus } from '../components/deals/DealCard';
import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import { ICommonParams } from '../domain/services/common';
import {
  IDealsCancelData,
  IDealsCancelParams,
  IDealsGetData,
  IDealsGetParams,
  IDealsListData,
  IDealsListParams,
  IDealsNewData,
  IDealsNewParams,
  IDealsRunData,
  IDealsRunParams,
  IDealsSendNoAgreementData,
  IDealsSendNoAgreementParams,
  IDealsSetMaskParams,
  IDealsSetRequisiteData,
  IDealsSetRequisiteParams,
  IFastDealCancelData,
  IFastDealCancelParams,
  IFastDealGetData,
  IFastDealGetParams,
  IFastDealNewData,
  IFastDealNewParams,
  IFastDealRunData,
  IFastDealRunParams,
} from '../domain/services/DealsServiceEntities';
import { getAuthHeader } from '../helpers/authHeader';
import { nvl, parseDate, toUpperCase } from '../helpers/utils';
import { generalRequest } from '../utils/axiosSetup';
import { ActiveDeal, DealShort } from '../utils/types';

const getDealStatus = (deal: ActiveDeal): DealStatus => {
  if (deal.dispute && deal.state !== 'closed') {
    return 'dispute';
  }
  if (deal.state === 'closed') {
    return 'closed';
  }
  if (deal.state === 'deleted') {
    return 'canceled';
  }
  if (deal.state === 'paid') {
    return 'paid';
  }
  if (deal.state === 'proposed') {
    return 'proposed';
  }
  if (deal.state === 'confirmed') {
    return 'confirmed';
  }
  return 'active';
};

export default abstract class DealsService {
  // OK
  static list = async (
    params: IDealsListParams,
    onSuccess?: ISuccessCallbackType<IDealsListData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealsListData>({
      url: '/deals',
      params: _.omit(
        {
          ...params,
          lot_type: params.type,
          currency: params.subCurrency,
        },
        ['type', 'subCurrency'],
      ),
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static dealsShortList = async (
    config: IDealsFilter & ICommonParams,
  ): Promise<DealShort[]> =>
    new Promise((resolve) =>
      DealsService.list(
        {
          symbol: config.currency !== 'all' ? config.currency.toLowerCase() : undefined,
          type:
            config.dealType !== 'all'
              ? config.dealType === 'purchase'
                ? 'buy'
                : 'sell'
              : undefined,
          offset: config.offset,
          limit: config.limit,
          page: config.page,
          broker:
            config.paymentMethod.name !== 'all' ? config.paymentMethod.name : undefined,
          subCurrency:
            config.subCurrency !== 'all' ? config.subCurrency.toLowerCase() : undefined,
        },
        (response) => {
          const newDeals: DealShort[] = [];
          if (response) {
            for (const deal of response) {
              const type = deal.type === 'buy' ? 'purchase' : 'sale';
              newDeals.push({
                id: deal.id,
                currency: nvl(toUpperCase(deal.symbol), ''),
                brokerId: deal.broker_id,
                type,
                status: getDealStatus(deal),
                amount: deal.amount,
                statusShown: deal.status_shown,
                amount_currency: deal.amount_currency,
                subCurrency: nvl(toUpperCase(deal.currency), 'RUB'),
                agent: deal.opponent,
                date: deal.created_at ? parseDate(deal.created_at) : undefined,
              });
            }
          }
          resolve(newDeals);
        },
      ),
    );

  // OK
  static get = async (
    params: IDealsGetParams,
    onSuccess?: ISuccessCallbackType<IDealsGetData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealsGetData>({
      url: `/deals/${params.id}`,
      params: { with_merchant: params.with_merchant },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static sendNoAgreement = async (
    params: IDealsSendNoAgreementParams,
    onSuccess?: ISuccessCallbackType<IDealsSendNoAgreementData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealsSendNoAgreementData>({
      url: `/deals/${params.id}/approve-no-agreement`,
      method: 'POST',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static new = async (
    params: IDealsNewParams,
    onSuccess?: ISuccessCallbackType<IDealsNewData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealsNewData>({
      url: '/deals',
      method: 'POST',
      data: params.deal,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static run = async (
    params: IDealsRunParams,
    onSuccess?: ISuccessCallbackType<IDealsRunData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealsRunData>({
      url: '/deals/states',
      method: 'PATCH',
      data: { deal_id: params.id },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static cancel = async (
    params: IDealsCancelParams,
    onSuccess?: ISuccessCallbackType<IDealsCancelData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealsCancelData>({
      url: '/deals/cancel',
      method: 'POST',
      data: { deal_id: params.id },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static setRequisite = async (
    params: IDealsSetRequisiteParams,
    onSuccess?: ISuccessCallbackType<IDealsSetRequisiteData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealsSetRequisiteData>({
      url: '/deals/requisite',
      method: 'PATCH',
      data: { deal_id: params.id, requisite: params.requisite },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static setMask = async (
    params: IDealsSetMaskParams,
    onSuccess?: ISuccessCallbackType<null>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<null>({
      url: '/deals/mask',
      method: 'PATCH',
      params: { validation_required: 'false' },
      data: { deal_id: params.dealId, mask: params.mask },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static fastDealGet = async (
    params: IFastDealGetParams,
    onSuccess?: ISuccessCallbackType<IFastDealGetData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealGetData>({
      url: `/deals/${params.id}`,
      headers: getAuthHeader(params.token),
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static fastDealNew = async (
    params: IFastDealNewParams,
    onSuccess?: ISuccessCallbackType<IFastDealNewData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealNewData>({
      url: `/deals`,
      method: 'POST',
      headers: getAuthHeader(params.token),
      data: {
        lot_id: params.bidId,
        amount_currency: params.amountCurrency,
        type: 1,
        address: params.address,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static fastDealRun = async (
    params: IFastDealRunParams,
    onSuccess?: ISuccessCallbackType<IFastDealRunData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealRunData>({
      url: '/deals/states',
      method: 'PATCH',
      headers: getAuthHeader(params.token),
      data: { deal_id: params.id },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static fastDealCancel = async (
    params: IFastDealCancelParams,
    onSuccess?: ISuccessCallbackType<IFastDealCancelData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealCancelData>({
      url: '/deals/cancel',
      method: 'POST',
      headers: getAuthHeader(params.token),
      data: { deal_id: params.id },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static getFastDealStatus = async (
    onSuccess?: ISuccessCallbackType<{ is_active: boolean }>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<{ is_active: boolean }>({
      url: '/settings/fast-deal-active-status',
      method: 'GET',
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
