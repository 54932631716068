import { notificationUpdater } from '../components/topBar/UserNotifications';
import { getItem, removeItem, setItem } from '../utils/localStorage';
import { BankType, Region } from '../utils/types';
import { getAuthToken } from './authHeader';

export const APP_VERSION = 'v1.9.52';
export const APP_NAME = 'Skycrypto.me';

export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_V2 = process.env.REACT_APP_API_URL_V2;
export const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
export const CAPTCHA_SITE_KEY_V3 = process.env.REACT_APP_RECAPTCHA_KEY_V3 || '';
export const CAPTCHA_SITE_KEY_V2 = process.env.REACT_APP_RECAPTCHA_KEY_V2;
export const BTC_URL = process.env.REACT_APP_BTC_URL;
export const ETH_URL = process.env.REACT_APP_ETH_URL;
export const USDT_URL = process.env.REACT_APP_USDT_URL;
export const APP_ENVIRONMENT = process.env.REACT_APP_ENV;

export const WAIT_FOR_PAYMENT_MINUTES = 20;

const USER_EMAIL = 'user_email';
const USER_CURRENCY = 'user_currency';
const USER_BALANCE = 'user_balance';
const USER_CREDENTIALS = 'user_credentials';
const USER_REGISTERED = 'user_registered';
const USER_IS_ADMIN = 'user_is_admin';
const REFERRAL = 'referral';
const MARKETING_CAMPAIGN = 'marketing_campaign';
const FAST_DEAL_INPUT = 'fast_deal_input';
const P2P_FILTER = 'p2p_filter';
const BIDS_FILTER = 'bids_filter';
const DEALS_FILTER = 'deals_filter';
const ROWS_PER_PAGE = 'rows_per_page';
const REFRESH_TOKEN = 'sky_refresh_token';
const USER_REGION = 'user_region';

export const LINK_SKY_BANKER = 'https://t.me/sky_banker';
export const LINK_SKY_BTC_BOT = 'https://t.me/sky_btc_bot';
export const LINK_SKY_ETH_BOT = 'https://t.me/sky_eth_bot';
export const LINK_SKY_USDT_BOT = 'https://t.me/sky_usdt_bot';
export const LINK_SKY_CHAT = 'https://t.me/SkyChatRu';
export const LINK_SKY_CRYPTO_SUPPORT = 'https://t.me/SKY_CRYPTO_SUPPORT';

export const MAX_BEFORE_E = 100000000000000000000;
export const DEFAULT_LIMIT = 10;

export function getUserRegion(): Region | undefined {
  return getItem(USER_REGION);
}

export function setUserRegion(value: Region) {
  setItem(USER_REGION, value);
}

export function getUserCurrency(): string {
  return getItem(USER_CURRENCY) || 'RUB';
}

export function getUserBalance(): string {
  return getItem(USER_BALANCE) || 'RUB';
}

export function setUserCurrency(currency: string) {
  setItem(USER_CURRENCY, currency);
}

export function setUserBalance(currency: string) {
  setItem(USER_BALANCE, currency);
}

let currentUser: string | undefined = '';

export function getCurrentUser(): string {
  if (!currentUser) {
    currentUser = getUserFromToken();
  }
  return currentUser;
}

export function setCurrentUser(user?: string) {
  currentUser = user;
}

export function getUserEmail(): string {
  return getItem(USER_EMAIL);
}

export function setUserEmail(email: string) {
  setItem(USER_EMAIL, email);
}

export function getUserCredentials(): { password?; secret?: string } {
  return getItem(USER_CREDENTIALS) || {};
}

export function setUserCredentials(password?, secret?: string) {
  setItem(USER_CREDENTIALS, { password, secret });
}

export function setRefreshToken(refreshToken: string) {
  setItem(REFRESH_TOKEN, refreshToken);
}

export function getRefreshToken() {
  return getItem(REFRESH_TOKEN);
}

export function getReferral(): string | undefined {
  return getItem(REFERRAL);
}

export function setReferral(ref?: string) {
  if (ref) {
    setItem(REFERRAL, ref);
  } else {
    removeItem(REFERRAL);
  }
}

export function getMarketingCampaign(): string | undefined {
  return getItem(MARKETING_CAMPAIGN);
}

export function setMarketingCampaign(cmp?: string) {
  if (cmp) {
    setItem(MARKETING_CAMPAIGN, cmp);
  } else {
    removeItem(MARKETING_CAMPAIGN);
  }
}

export function getRegistered(): boolean {
  return getItem(USER_REGISTERED) === 'true';
}

export function setRegistered(registered: boolean) {
  setItem(USER_REGISTERED, registered);
}

export function getUserIsAdmin(): boolean {
  return getItem(USER_IS_ADMIN) === 'true';
}

export function setUserIsAdmin(isAdmin: boolean) {
  setItem(USER_IS_ADMIN, isAdmin);
}

interface FastDealInput {
  email: string;
  token: string;
  address: string;
  paymentMethod?: BankType;
  crypto?: string;
  currency?: string;
  amountCrypto?: number;
  amountCurrency?: number;
  dealId?: string;
  finished?: boolean;
}

export function getFastDealInput(): FastDealInput {
  return getItem(FAST_DEAL_INPUT) || {};
}

export function setFastDealInput(input: FastDealInput) {
  setItem(FAST_DEAL_INPUT, input);
}

export function clearFastDealInput() {
  removeItem(FAST_DEAL_INPUT);
}

let fastDealInputTemp: FastDealInput | undefined;

export function getFastDealInputTemp(): FastDealInput | undefined {
  return fastDealInputTemp;
}

export function setFastDealInputTemp(input?: FastDealInput) {
  fastDealInputTemp = input;
}

interface P2PFilter {
  bidType: string;
  currency: string;
  paymentMethod: BankType;
  subCurrency: string;
}

export function getP2PFilter(): P2PFilter {
  return getItem(P2P_FILTER) || {};
}

export function setP2PFilter(filter: P2PFilter) {
  setItem(P2P_FILTER, filter);
}

interface DealsFilter {
  dealType: string;
  currency: string;
  subCurrency: string;
}

interface Filter {
  type: string;
  currency: string;
  subCurrency: string;
}

export function getBidsFilter(): Filter {
  return getItem(BIDS_FILTER) || {};
}

export function setBidsFilter(filter: Filter) {
  setItem(BIDS_FILTER, filter);
}

export function getDealsFilter(): DealsFilter {
  return getItem(DEALS_FILTER) || {};
}

export function setDealsFilter(filter: DealsFilter) {
  setItem(DEALS_FILTER, filter);
}

export function getRowsPerPage(): number {
  return Number(getItem(ROWS_PER_PAGE)) || 10;
}

export function setRowsPerPage(rows: number) {
  setItem(ROWS_PER_PAGE, rows);
}

function getUserFromToken(): string {
  const claims = parseJwt(getAuthToken());
  return claims ? claims.nickname : '';
}

export function parseJwt(token: string): any {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export const UpdaterSettingsDefault = {
  generalUpdaterTime: 20000,
  bidsUpdaterTime: 10000,
  messagesUpdaterTime: 3000,
  ratesUpdaterTime: 30000,
  notificationsUpdaterTime: 5000,
};
