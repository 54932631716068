import React from 'react';

import { formatCrypto, formatNumber } from '../../helpers/utils';
import { Defaults, TextEx } from '../common';

const Amount = ({
  amount,
  crypto,
  currency,
  size = '1rem',
  color,
}: {
  amount: number;
  crypto?;
  currency?: string;
  size?: number | string;
  color?: string;
}) => {
  const Color = color || (amount <= 0 ? Defaults.redColor : Defaults.greenColor);
  return (
    <TextEx size={size} color={Color}>
      {crypto ? formatCrypto(amount, crypto) : formatNumber(amount)}
      &nbsp;
      {crypto || currency}
    </TextEx>
  );
};

export default Amount;
