import { Currency } from '../store/Currency';

export interface UserProfile {
  nickname: string;
  deals: {
    amount_currency: number;
    deals: number;
    likes: number;
    dislikes: number;
  };
  rating: number;
  currency: string;
  registered: number;
  verified: boolean;
  email?: string;
  online?: boolean;
  is_mfa_enabled?: boolean;
  isa?: boolean;
}

export interface Region {
  id: string;
  value: any;
}

export interface CurrencyBalance {
  currency: string;
  amount: number;
}

export interface Rate {
  currency: string;
  rates: SubRate[];
}

export interface SubRate {
  currency: string;
  rate: number;
}

export interface FastDealRate {
  symbol: string;
  currency: string;
  rate: number;
}

export interface Wallet {
  address: string;
  symbol: string;
  balance: number;
  frozen: number;
  is_active_sell: boolean;
  is_active_buy: boolean;
  is_active_auto_requisites: boolean;
}

export type OperationType =
  | 'all'
  | 'deal'
  | 'promocode'
  | 'transaction'
  | 'referral'
  | 'sky pay'
  | 'sky sale'
  | 'sky sale v2'
  | 'withdrawal'
  | 'cpayment';

export type OperationV2Type = 'pay' | 'pay_v2' | 'sale' | 'sale_v2' | 'cpayment';

export const operationTypes = {
  deal: 1,
  transaction: 2,
  promocode: 3,
  referral: 4,
  'sky pay': 5,
  'sky sale': 6,
  'sky sale v2': 7,
  withdrawal: 8,
  cpayment: 9,
  'sky pay v2': 10,
  exchange: 11,
};

export const dealTypes = {
  plain: 0,
  fast: 1,
  sky_pay: 2,
  sky_sell: 3,
  sky_sale_v2: 4,
  sky_pay_v2: 5,
};

export interface Operation {
  id: string;
  created_at: string; // need to be parsed to Date
  amount: number;
  state?: 'closed' | 'deleted';
  amount_currency: number;
  symbol: string;
  currency?: string | null;
  label?: string;
  action: string;
  status?: number;
}

export interface SkyPayOperation extends Operation {
  amount_currency: number;
  commission: number;
  currency: string;
  is_currency_amount?: boolean;
}

export interface PaymentMethod {
  is_card?: boolean;
  id: string;
  name: string;
  lots: number;
  logo?: string;
}

export interface CurrencyList {
  id: string;
}

export interface BankType {
  id: string;
  is_card?: boolean;
  name: string;
  logo?: string;
  available_currencies?: string[];
}

export type BidType = 'buy' | 'sell';

export interface Bid {
  id?: string;
  type?: BidType;
  symbol?: string;
  broker: BankType;
  broker_id: string;
  rate?: number;
  coefficient?: number;
  ordering: number;
  limit_from?: number;
  limit_to?: number;
  amount_limit?: number;
  currency?: string;
  details?: string;
  is_active?: boolean;
  is_active_auto_requisites?: boolean;
  requisites?: string;
}

export interface CreateBid extends Omit<Bid, 'broker' | 'broker_id'> {
  broker?: string;
}

export interface UpdateBid {
  id: string;
  rate?: number;
  coefficient?: number;
  limit_from?: number;
  limit_to?: number;
  details?: string;
  ordering?: number;
  is_active?: boolean;
  captcha_token?: string;
  is_active_auto_requisites?: boolean;
  requisites?: string;
}

export interface Broker {
  id: string;
  name: string;
}

export interface MarketBid extends Bid {
  user: UserProfile;
}

export interface DealBid extends Bid {
  user: string;
}

export type DealState = 'new' | 'proposed' | 'confirmed' | 'paid' | 'closed' | 'deleted';

export interface Deal {
  id: string;
  symbol: string;
  lot: DealBid;
  rate: number;
  state: DealState;
  amount: number;
  amount_currency: number;
  seller: UserProfile;
  seller_commission: number;
  confirmed_at: string; // need to be parsed to Date
  buyer: UserProfile;
  buyer_commission: number;
  referral_commission_buyer: number;
  referral_commission_seller: number;
  requisite: string;
  created_at: string; // need to be parsed to Date
  end_time: string; // need to be parsed to Date
  expire_in: number | null;
  voted: boolean;
  type: number;
  cancel_reason: string | null;
  dispute: {
    initiator: string;
    opponent: string | null;
    created_at: string; // need to be parsed to Date
  } | null;
  mask?: string | null | undefined;
  required_mask?: boolean;
}

export interface DealShort {
  id: string;
  currency: string;
  type: string;
  status: string;
  amount: number;
  amount_currency: number;
  subCurrency: number;
  agent: string;
  date?: Date;
  statusShown?: number;
  brokerId?: string;
}

export interface ActiveDeal {
  id: string;
  symbol: string;
  currency: string;
  type: string;
  state: DealState;
  amount: number;
  amount_currency: number;
  opponent: string;
  created_at: string; // need to be parsed to Date
  // broker: BankType;
  status_shown?: number;
  broker_id?: string;
  dispute: boolean | null;
  is_lot_owner: boolean;
}

export interface NewDeal {
  lot_id: string;
  rate?: number; // crypto rate
  amount_currency?: number; // amount in fiat currency
  amount?: number; // amount in crypto
  requisite?: string;
}

export interface DealResponse {
  success: string;
}

export interface Dispute {
  initiator: string;
  opponent: string;
  created_at: string; // need to be parsed to Date
}

export interface Promocode {
  id?: string;
  symbol: string;
  amount: number;
  activations?: number;
  count?: number;
  user?: string;
  created_at?: string; // need to be parsed to Date
  is_currency_amount?: boolean;
  currency?: string;
}

export interface ActivatedPromocode {
  activated_at: string; // need to be parsed to Date
  promocode: Promocode;
}

export interface Referrals {
  earned: Array<{
    symbol: string;
    amount: number;
    invited: number;
    ref_code: string;
  }>;
  invited_count: number;
}

export interface Message {
  created_at: string; // need to be parsed to Date
  receiver: string;
  sender: string;
  message?: string;
  media?: string;
}

export interface SendMessage {
  receiver: string;
  message?: string;
  media_id?: number;
  symbol?: string;
}

export interface Notification {
  id: number;
  type:
    | 'deal'
    | 'cancel_deal'
    | 'income_referral'
    | 'message'
    | 'timeout'
    | 'transaction'
    | 'dispute'
    | 'closed_dispute'
    | 'promocode'
    | 'exchange'
    | 'before_timeout'
    | 'system_message'
    | 'deal_autorequisites';
  created_at?: number;
  created_ago?: number;
  is_read: boolean;
  details: any;
}

export interface ICurrency {
  id: string;
  rate_variation: number;
}
export interface Commission {
  symbol: string;
  commission: number;
  commission_buyer: number;
  min_amount?: number;
  rate_variation?: string;
  currencies: ICurrency[];
}

export interface JoinAccountStatus {
  joined: boolean;
}

export interface Merchant {
  name: string;
  website?: string;
  commission?: number;
  image_url?: string | Blob;
  image_name?: string;
  callback_url?: string;
  callback_url_sale?: string;
  callback_type?: number;
  callback_url_cpay?: string;
  callback_safe?: boolean;
  required_mask?: boolean;
}

export type CommissionForTransfer = Record<string, StableAndDynamicCommissions>;

export interface StableAndDynamicCommissions {
  stable?: number;
  dynamic?: Array<Array<number>>;
}

export type TCurrencies = 'azn' | 'byn' | 'kzt' | 'rub' | 'tjs' | 'uah' | 'usd' | 'uzs';
export type TDealType = 'cpayment' | 'pay' | 'sale' | 'withdrawal_v2';
