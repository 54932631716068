import { useState } from 'react';
import styled from 'styled-components/macro';

import { setPage } from '../../config/history';
import { t, tt } from '../../config/i18n';
import { APP_NAME, getCurrentUser } from '../../helpers/settings';
import {
  formatCrypto,
  formatNumber,
  formatString,
  getCryptoFracDigits,
  nvl,
} from '../../helpers/utils';
import DealsService from '../../services/DealsService';
import { BankType, DealState, dealTypes } from '../../utils/types';
import { Defaults, px, Spacer, TextEx, Timer } from '../common';
import ActionButton from '../controls/ActionButton';
import { ChatUserStatus } from '../controls/Chat';
import { IconChecked } from '../controls/CheckboxEx';
import { Confirm, DialogTextLine, MessageBox } from '../controls/Dialog';
import InputEx from '../controls/InputEx';
import {
  pageBorderColor,
  pageFontSize,
  PageReadOnlyValue,
  PageText,
} from '../controls/Page';
import SelectEx from '../controls/SelectEx';
import Txt from '../controls/Txt';
import { LikeIcon } from '../controls/User';
import { CurrencyBox } from '../currencies';
import ProgressiveTimer from '../universalComponents/ProgressiveTimer';

const controlHeight = '3.75rem';
const buttonWidth = '14rem';

export interface DealPartner {
  name: string;
  deals?: number;
  rating?: number;
  likes?: number;
  dislikes?: number;
  verified: boolean;
  duration?: number;
  status?: ChatUserStatus;
}

export interface Deal {
  id: string;
  bidId?: string;
  type: 'purchase' | 'sale';
  dealType?: number;
  state: DealState;
  partner: DealPartner;
  currency: string;
  subCurrency: string;
  paymentMethod: BankType;
  amount?: number;
  subAmount?: number;
  rate: number;
  limitMin: number;
  limitMax: number;
  tradeCondition?: string;
  requisite?: string;
  purchaseCommission?: number;
  saleCommission?: number;
  expireIn?: number;
  expireTime?: Date;
  voted: boolean;
  confirmedAt?: Date;
  cancelReason?: string;
  dispute?: {
    initiator: string;
    opponent?: string;
  };
  isBidOwner: boolean;
  mask?: string | null | undefined;
  requiredMask?: boolean;
}

export type DealStep =
  | 'open'
  | 'apply'
  | 'wait-requisites'
  | 'send-requisites'
  | 'payment'
  | 'wait-payment'
  | 'wait-coins'
  | 'send-coins'
  | 'close'
  | 'done'
  | undefined;

export const DealStepsPanel = ({
  steps,
  current,
}: {
  steps: DealStep[];
  current: number;
}) => (
  <DealStepsPanelRoot>
    {steps.map((step, n) => {
      const completed = n < current;
      const active = n === current;
      return (
        <div
          key={n}
          className={`step ${completed ? 'completed' : active ? 'active' : ''}`}>
          <div className={'marker'}>
            {completed ? (
              <IconChecked size={'1rem'} fill={'white'} />
            ) : (
              <DealText color={'inherit'}>{n + 1}</DealText>
            )}
          </div>
          <PageText k={`deal.step.${step}`} color={'inherit'} left={'.8125rem'} />
        </div>
      );
    })}
  </DealStepsPanelRoot>
);

export const DealStepCaption = ({
  n,
  step,
  req,
}: {
  n: number;
  step: DealStep;
  req: string;
}) => (
  <DealStepCaptionRoot>
    {step !== 'done' ? (
      <>
        <PageText
          k={'deal.step-caption'}
          args={[n + 1]}
          size={'1.125rem'}
          color={Defaults.mainColor}
        />
        <Spacer width={'0.6vh'} />
        <PageText k={`deal.step.${step}`} args={[req]} size={'1.125rem'} />
      </>
    ) : (
      <>
        <Column center>
          <PageText k={`deal.step.done`} size={'1.125rem'} />
          <PageText k={`deal.requisites`} args={[req]} size={'1.125rem'} />
        </Column>
      </>
    )}
  </DealStepCaptionRoot>
);

export const DealOpeningPanel = ({
  deal,
  amount,
  subAmount,
  onAmountChange,
  onSubAmountChange,
  onOpen,
  disabled,
}: {
  deal: Deal;
  amount?;
  subAmount?: number;
  onAmountChange;
  onSubAmountChange: (value?: number) => void;
  onOpen: () => void;
  disabled: boolean;
}) => (
  <DealPanelRoot>
    <Row>
      <Column bottom={'1rem'}>
        <PageText
          k={`deal.amount-${deal.type === 'sale' ? 'get' : 'give'}`}
          size={pageFontSize}
        />
        <Spacer height={'.8125rem'} />
        <InputEx
          value={subAmount}
          type={'number'}
          onChange={onSubAmountChange}
          hint={t('deal.amount-hint')}
          borderColor={pageBorderColor}
          fontSize={pageFontSize}
          width={'14rem'}
          height={controlHeight}
          img={<CurrencyBox c={deal.subCurrency} size={'2.8rem'} left={'-0.3125rem'} />}
          imgWidth={'3.75rem'}
          imgPos={'left'}
          maxFracDigits={2}
          placeholder={
            subAmount ? (
              <DealText left={'-.8125rem'} top={'1px'}>
                {formatNumber(subAmount)}
              </DealText>
            ) : undefined
          }
        />
      </Column>
      <Column bottom={'1rem'} left={'.75rem'}>
        <PageText
          k={`deal.amount-${deal.type === 'sale' ? 'give' : 'get'}`}
          size={pageFontSize}
        />
        <Spacer height={'.8125rem'} />
        <InputEx
          value={amount}
          type={'number'}
          onChange={onAmountChange}
          borderColor={pageBorderColor}
          fontSize={pageFontSize}
          width={'14rem'}
          height={controlHeight}
          img={<CurrencyBox c={deal.currency} size={'2.8rem'} left={'-0.3125rem'} />}
          imgWidth={'3.75rem'}
          imgPos={'left'}
          maxFracDigits={getCryptoFracDigits(deal.currency)}
          placeholder={
            subAmount ? (
              <DealText left={'-.8125rem'} top={'1px'}>
                {formatCrypto(amount, deal.currency)}
              </DealText>
            ) : undefined
          }
        />
      </Column>
    </Row>
    <Row top={'1.875rem'}>
      <ActionButton
        caption={'deal.action.open'}
        width={buttonWidth}
        onClick={onOpen}
        busy={disabled}
      />
    </Row>
  </DealPanelRoot>
);

export const DealApplyPanel = ({
  deal,
  onApply,
  onCancel,
  disabled,
}: {
  deal: Deal;
  onApply;
  onCancel: () => void;
  disabled: boolean;
}) => (
  <DealPanelRoot>
    <HtmlText
      k={'deal.apply-confirmation-1'}
      args={[
        tt('deal.open-action', deal.type === 'sale' ? 'purchase' : 'sale'),
        deal.partner.name,
        formatCrypto(deal.amount, deal.currency),
        deal.currency,
        deal.subAmount,
        deal.subCurrency,
        deal.paymentMethod?.name,
        deal.rate,
      ]}
    />
    <HtmlText k={'deal.apply-confirmation-2'} size={'1rem'} top={'1vh'} />
    <ProgressiveTimer time={deal.expireIn ?? 0} top={'1vh'} />
    <HtmlText k={'deal.warning-requisites'} size={'1rem'} top={'1vh'} />
    <HtmlText
      k={'deal.apply-confirmation-3'}
      size={'1rem'}
      args={[formatCrypto(deal.saleCommission, deal.currency), deal.currency]}
    />
    <Row top={'1.875rem'}>
      <ActionButton
        caption={'deal.action.apply'}
        width={buttonWidth}
        onClick={onApply}
        busy={disabled}
      />
      <Spacer width={'1rem'} />
      <ActionButton
        caption={'deal.action.cancel'}
        width={buttonWidth}
        buttonColor={Defaults.redColor}
        hoverColor={Defaults.redColor}
        swapColors
        onClick={onCancel}
        busy={disabled}
      />
    </Row>
  </DealPanelRoot>
);

export const DealWaitRequisitesPanel = ({
  deal,
  onSendWithoutApprovement,
}: {
  deal: Deal;
  onSendWithoutApprovement: () => void;
}) => (
  <DealPanelRoot>
    <HtmlText k={'deal.open-confirmed'} args={[deal.id]} size={'1.125rem'} />
    <HtmlText k={'deal.wait-requisites'} top={'1vh'} lineHeight={'1.875rem'} />
    {deal.expireIn !== undefined && <ProgressiveTimer time={deal.expireIn} top={'1vh'} />}
    {deal?.partner?.name.startsWith('fs') && (
      <ActionButton
        top={'1vh'}
        caption={'deal.send-without-approvement'}
        width={buttonWidth}
        buttonColor={Defaults.greenColor}
        hoverColor={Defaults.greenColor}
        swapColors
        onClick={onSendWithoutApprovement}
      />
    )}
  </DealPanelRoot>
);

export const DealSendRequisitesPanel = ({
  deal,
  requisite,
  lastRequisites,
  onChange,
  onAccept,
  onReject,
  disabled,
}: {
  deal: Deal;
  requisite?: string;
  lastRequisites?: string[];
  onChange: (requisite?: string) => void;
  onAccept;
  onReject: () => void;
  disabled: boolean;
}) => {
  const [requisitesError, setRequisitesError] = useState<boolean>(false);
  const dummyState = { requisitesError: false };
  const setDummyRequisitesError = (value) => {
    dummyState.requisitesError = value;
    setRequisitesError(dummyState.requisitesError);
  };

  function validateRequisites() {
    if (!requisite) {
      setDummyRequisitesError(true);
      const interval = setInterval(
        () => setDummyRequisitesError(!dummyState.requisitesError),
        400,
      );
      setTimeout(() => {
        setDummyRequisitesError(false);
        clearInterval(interval);
      }, 2000);
      return;
    }
    onAccept();
  }

  if (lastRequisites) {
    lastRequisites = lastRequisites.slice(0, 10);
  }

  return (
    <DealPanelRoot>
      <HtmlText
        k={'deal.send-requisites-1'}
        args={[deal.paymentMethod?.name]}
        size={'1.125rem'}
      />
      <HtmlText k={'deal.warning-requisites'} />
      <HtmlText k={'deal.validation-rules-requisites'} />
      <SelectEx
        id={requisite}
        onChange={onChange}
        top={'1vh'}
        width={'90%'}
        height={controlHeight}
        options={
          lastRequisites
            ? lastRequisites.map((id) => ({ id, textAlign: 'center' }))
            : undefined
        }
        borderColor={requisitesError ? Defaults.redColor : pageBorderColor}
        hoverColor={'unset'}
        component={
          <InputEx
            value={requisite}
            type={'text'}
            width={'99%'}
            onChange={onChange}
            autoFocus
            height={'3.8vh'}
            textAlign={'center'}
            fontSize={pageFontSize}
            onInputClick={(e) => e.stopPropagation()}
          />
        }
      />
      <HtmlText k={'deal.send-requisites-2'} top={'1vh'} />
      {deal.expireIn !== undefined && (
        <ProgressiveTimer time={deal.expireIn} top={'1vh'} />
      )}
      <Row top={'1.875rem'}>
        <ActionButton
          caption={'deal.action.confirm-requisite'}
          width={buttonWidth}
          onClick={validateRequisites}
          busy={disabled}
          disabled={disabled}
        />
        <Spacer width={'1rem'} />
        <ActionButton
          caption={'deal.action.cancel'}
          width={buttonWidth}
          buttonColor={Defaults.redColor}
          hoverColor={Defaults.redColor}
          swapColors
          onClick={onReject}
        />
      </Row>
    </DealPanelRoot>
  );
};

export const DealPaymentPanel = ({
  deal,
  onConfirm,
  onCancel,
  disabled,
}: {
  deal: Deal;
  onConfirm;
  onCancel: () => void;
  disabled: boolean;
}) => (
  <DealPanelRoot>
    <HtmlText
      k={'deal.payment-1'}
      size={'1.125rem'}
      args={[formatNumber(deal.subAmount), deal.subCurrency]}
    />
    {deal.tradeCondition ? (
      <>
        <HtmlText k={'deal.payment-2'} top={'1vh'} args={['']} />
        <span style={{ wordBreak: 'break-word' }}>{deal.tradeCondition}</span>
      </>
    ) : undefined}
    <HtmlText k={'deal.payment-3'} args={[deal.paymentMethod?.name]} />
    <HtmlText k={'deal.payment-4'} color={Defaults.mainColor} top={'1vh'} />
    <PageReadOnlyValue
      textAlign={'center'}
      minHeight={controlHeight}
      width={'100%'}
      top={'1vh'}>
      {deal.requisite}
    </PageReadOnlyValue>
    <HtmlText k={'deal.payment-5'} top={'1vh'} />
    {deal.expireIn !== undefined && <ProgressiveTimer time={deal.expireIn} top={'1vh'} />}
    <Row top={'1.875rem'}>
      <ActionButton
        caption={'deal.action.confirm-payment'}
        width={'200px'}
        onClick={onConfirm}
        busy={disabled}
      />
      <Spacer width={'1rem'} />
      <ActionButton
        caption={'deal.action.cancel'}
        width={'200px'}
        buttonColor={Defaults.redColor}
        hoverColor={Defaults.redColor}
        swapColors
        onClick={onCancel}
        busy={disabled}
      />
    </Row>
  </DealPanelRoot>
);

export const DealWaitPaymentPanel = ({
  deal,
  onConfirm,
  disabled,
}: {
  deal: Deal;
  onConfirm: () => void;
  disabled: boolean;
}) => (
  <DealPanelRoot>
    <HtmlText
      k={'deal.requisites'}
      size={'1.125rem'}
      args={[`<b>${deal.requisite}</b>`]}
    />
    <HtmlText
      k={'deal.wait-payment-1'}
      top={'16px'}
      size={'1.125rem'}
      args={[formatNumber(deal.subAmount), deal.subCurrency, deal.paymentMethod?.name]}
    />
    <HtmlText
      k={'deal.wait-payment-2'}
      args={[deal.partner.name]}
      top={'1vh'}
      bottom={'1vh'}
    />
    {[dealTypes.sky_pay_v2, dealTypes.sky_pay].includes(deal.dealType!) && (
      <ActionButton
        caption={'deal.action.send-coins-no-agreement'}
        width={'293px'}
        onClick={onConfirm}
        busy={disabled}
      />
    )}
    {deal.expireIn && <ProgressiveTimer time={deal.expireIn} top={'1vh'} />}
  </DealPanelRoot>
);

export const filterDealSteps = (...steps: DealStep[]): DealStep[] => {
  const filtered: DealStep[] = [];
  for (const step of steps) {
    if (step) {
      filtered.push(step);
    }
  }
  return filtered;
};

export const canOpenDispute = (deal: Deal): boolean => {
  return (
    !deal.dispute ||
    (deal.dispute.initiator !== getCurrentUser() && !deal.dispute.opponent)
  );
};

export const DealWaitCoinsPanel = ({
  deal,
  onOpenDispute,
  disabled,
}: {
  deal: Deal;
  onOpenDispute: () => void;
  disabled: boolean;
}) => {
  const expired = deal.expireIn !== undefined && deal.expireIn === 0;
  return (
    <DealPanelRoot error={expired}>
      {canOpenDispute(deal) ? (
        <>
          {!expired ? (
            <>
              <HtmlText k={'deal.wait-coins-1'} size={'1.125rem'} />
              <HtmlText k={'deal.wait-coins-2'} top={'1vh'} />
              <HtmlText k={'deal.support-address'} top={'1vh'} />
              <ProgressiveTimer time={deal.expireIn ?? 0} top={'1vh'} />
            </>
          ) : (
            <>
              <HtmlText k={'deal.wait-coins-timeout-1'} size={'1.125rem'} />
              <HtmlText k={'deal.wait-coins-timeout-2'} top={'1vh'} />
            </>
          )}
          {dealTypes.sky_sale_v2 !== deal.dealType && (
            <Row top={'1.875rem'}>
              <ActionButton
                caption={'deal.action.open-dispute'}
                width={buttonWidth}
                buttonColor={Defaults.redColor}
                hoverColor={Defaults.redColor}
                swapColors
                onClick={onOpenDispute}
                busy={disabled}
              />
            </Row>
          )}
        </>
      ) : !!deal.dispute && !!deal.dispute.initiator && !!deal.dispute.opponent ? (
        <HtmlText k={'deal.dispute-3'} size={'1.125rem'} args={[deal.id]} top={'1vh'} />
      ) : (
        <>
          <HtmlText k={'deal.dispute-1'} args={[deal.id]} top={'1vh'} />
          <HtmlText k={'deal.dispute-2'} top={'1vh'} />
        </>
      )}
    </DealPanelRoot>
  );
};

export const DealSendCoinsPanel = ({
  deal,
  onSendCoins,
  onOpenDispute,
  disabled,
}: {
  deal: Deal;
  onSendCoins;
  onOpenDispute: () => void;
  disabled: boolean;
}) => (
  <DealPanelRoot>
    <HtmlText
      k={'deal.send-coins-1'}
      size={'1.125rem'}
      args={[
        formatNumber(deal.subAmount),
        deal.subCurrency,
        deal.paymentMethod?.name,
        deal.id,
      ]}
    />
    <HtmlText
      k={'deal.send-coins-4'}
      size={'1.125rem'}
      args={[deal.requisite]}
      top={'1vh'}
    />
    {canOpenDispute(deal) ? (
      <>
        <HtmlText k={'deal.send-coins-2'} args={[deal.partner.name]} top={'1vh'} />
        <HtmlText k={'deal.send-coins-3'} top={'1vh'} />
        {deal.mask && (
          <HtmlText k={'deal.sender-requisites'} top={'1vh'} args={[deal.mask]} />
        )}
        {deal.expireIn !== undefined ? (
          <ProgressiveTimer time={deal.expireIn ?? 0} top={'1vh'} />
        ) : undefined}
        {(deal.partner.name.startsWith('fd') ||
          [dealTypes.sky_pay_v2, dealTypes.sky_sale_v2].includes(deal.dealType!)) && (
          <HtmlText k={'deal.dispute-locked-for'} top={'1vh'} />
        )}
      </>
    ) : deal.dispute && !!deal.dispute.initiator && !!deal.dispute.opponent ? (
      <HtmlText k={'deal.dispute-3'} size={'1.125rem'} args={[deal.id]} top={'1vh'} />
    ) : (
      <>
        <HtmlText k={'deal.dispute-1'} args={[deal.id]} top={'1vh'} />
        <HtmlText k={'deal.dispute-2'} top={'1vh'} />
      </>
    )}
    {deal.dealType !== dealTypes.sky_sale_v2 && (
      <Row top={'1.875rem'}>
        <ActionButton
          caption={'deal.action.send-coins'}
          width={'200px'}
          onClick={onSendCoins}
          busy={disabled}
        />
        <Spacer width={'1rem'} />
        {canOpenDispute(deal) ? (
          <ActionButton
            caption={'deal.action.open-dispute'}
            width={'200px'}
            buttonColor={Defaults.redColor}
            hoverColor={Defaults.redColor}
            swapColors
            onClick={onOpenDispute}
            disabled={
              disabled ||
              (() => {
                const now =
                  new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000; // normalize time to UTC
                const allowDisputeTime =
                  (deal?.confirmedAt?.getTime() ?? 0) + 5 * 60 * 1000; // + 5 minutes
                return (
                  (deal.partner.name.startsWith('fd') ||
                    [dealTypes.sky_pay_v2, dealTypes.sky_sale_v2].includes(
                      deal.dealType!,
                    )) &&
                  allowDisputeTime > now
                );
              })()
            }
            busy={disabled}
          />
        ) : undefined}
      </Row>
    )}
  </DealPanelRoot>
);

export const DealClosePanel = ({
  deal,
  onUserVote,
  disabled,
}: {
  deal: Deal;
  onUserVote: (method: string) => void;
  disabled: boolean;
}) => (
  <DealPanelRoot>
    <HtmlText
      k={deal.type === 'sale' ? 'deal.closed-sale' : 'deal.closed-purchase'}
      size={'1.125rem'}
      args={[
        formatCrypto(nvl(deal.amount, 0), deal.currency),
        deal.currency,
        deal.partner.name,
      ]}
    />
    <Row className={'d-flex btn-wrap-flex'} top={'1.875rem'}>
      <ActionButton
        width={'100px'}
        swapColors
        hoverColor={'rgba(0, 151, 219, 0.2)'}
        onClick={() => onUserVote('like')}
        busy={disabled}>
        <LikeIcon size={'18px'} fill={Defaults.mainColor} />
      </ActionButton>
      <Spacer width={'1rem'} />
      <ActionButton
        width={'100px'}
        swapColors
        buttonColor={Defaults.redColor}
        hoverColor={'rgba(253, 61, 52, 0.2)'}
        onClick={() => onUserVote('dislike')}
        busy={disabled}>
        <LikeIcon size={'18px'} fill={Defaults.redColor} flip />
      </ActionButton>
    </Row>
  </DealPanelRoot>
);

export const DealDonePanel = ({ deal }: { deal: Deal }) => (
  <DealPanelRoot>
    <HtmlText
      k={deal.type === 'sale' ? 'deal.closed-sale' : 'deal.closed-purchase'}
      size={'1.125rem'}
      args={[
        formatCrypto(nvl(deal.amount, 0), deal.currency),
        deal.currency,
        deal.partner.name,
      ]}
    />
    <HtmlText k={'deal.thanks'} top={'1vh'} />
  </DealPanelRoot>
);

export const DealCanceledPanel = ({ deal }: { deal: Deal }) => (
  <DealPanelRoot error noArrow>
    <HtmlText k={'deal.canceled-1'} size={'1.125rem'} />
    <HtmlText
      k={'deal.canceled-2'}
      top={'1vh'}
      args={[tt('deal.canceled-by', deal.cancelReason)]}
    />
    <Row top={'1.875rem'}>
      <ActionButton
        caption={'deal.action.open-p2p'}
        width={buttonWidth}
        onClick={() => setPage('p2p')}
      />
    </Row>
  </DealPanelRoot>
);

export const ConfirmOpenDialog = ({
  open,
  onConfirm,
  onCancel,
  type,
  amount,
  subAmount,
  rate,
  currency,
  subCurrency,
}: {
  open: boolean;
  onConfirm;
  onCancel: () => void;
  type: string;
  amount;
  subAmount;
  rate: number;
  currency;
  subCurrency: string;
}) => (
  <Confirm open={open} onSubmit={onConfirm} onCancel={onCancel}>
    <DialogTextLine width={'50vh'}>
      <HtmlText
        k={'deal.open-confirmation'}
        args={[
          tt('deal.open-action', type),
          formatCrypto(amount, currency),
          currency,
          subAmount,
          subCurrency,
          formatNumber(rate),
        ]}
        size={'1.125rem'}
      />
    </DialogTextLine>
  </Confirm>
);

export const ConfirmCancelDialog = ({
  open,
  onConfirm,
  onCancel,
  dealId,
}: {
  open: boolean;
  onConfirm;
  onCancel: () => void;
  dealId: string;
}) => (
  <Confirm open={open} onSubmit={onConfirm} onCancel={onCancel}>
    <DialogTextLine>
      <HtmlText k={'deal.cancel-confirmation'} args={[dealId]} size={'1.125rem'} />
    </DialogTextLine>
  </Confirm>
);

export const ConfirmPaymentDialog = ({
  open,
  onConfirm,
  onCancel,
  user,
  subAmount,
  subCurrency,
  paymentMethod,
}: {
  open: boolean;
  onConfirm;
  onCancel: () => void;
  user;
  subCurrency;
  paymentMethod: BankType;
  subAmount?: number;
}) => (
  <Confirm open={open} onSubmit={onConfirm} onCancel={onCancel}>
    <DialogTextLine>
      <HtmlText
        k={'deal.payment-confirmation'}
        size={'1.125rem'}
        args={[user, formatNumber(subAmount), subCurrency, paymentMethod?.name]}
      />
    </DialogTextLine>
  </Confirm>
);

export const ConfirmSendCoinsDialog = ({
  open,
  onConfirm,
  onCancel,
  noAgreement,
  deal,
}: {
  open: boolean;
  noAgreement?: boolean;
  onConfirm;
  onCancel: () => void;
  deal: Deal;
}) => {
  const [cardMask, setCardMask] = useState('');

  const {
    amount,
    currency,
    subAmount,
    subCurrency,
    partner: { name },
    id,
  } = deal;

  const onSubmit = () => {
    noAgreement
      ? DealsService.setMask({ dealId: id, mask: cardMask }).then(() => {
          onConfirm();
        })
      : onConfirm();
  };

  return (
    <Confirm
      open={open}
      onSubmit={onSubmit}
      onCancel={onCancel}
      disabled={noAgreement ? !cardMask.length : false}>
      <Column width={'75%'} center>
        <HtmlText k={'deal.send-coins-confirmation-1'} size={'1.125rem'} />
        <HtmlText
          k={'deal.send-coins-confirmation-2'}
          size={'1.125rem'}
          top={'1vh'}
          args={[formatNumber(subAmount), subCurrency, name]}
        />
        <HtmlText
          k={'deal.send-coins-confirmation-3'}
          top={'1vh'}
          args={[formatCrypto(amount, currency), currency]}
        />
        <HtmlText k={'deal.send-coins-confirmation-4'} top={'1vh'} />
        <HtmlText k={'deal.support-address'} top={'1vh'} />
        <HtmlText k={'deal.send-coins-confirmation-5'} top={'1vh'} />
        {noAgreement && (
          <InputEx
            type="text"
            borderColor="black"
            top={'1vh'}
            width={'300px'}
            value={cardMask}
            hint={t('deal.enter-card-mask')}
            onChange={setCardMask}
          />
        )}
      </Column>
    </Confirm>
  );
};

export const WrongSubAmountDialog = ({
  open,
  min,
  max,
  currency,
  onClose,
}: {
  open: boolean;
  min;
  max: number;
  currency: string;
  onClose: () => void;
}) => (
  <MessageBox open={open} onClose={onClose} caption={'common.error'}>
    <TextEx size={'1.125rem'}>
      <Txt
        k={'deal.amount-wrong'}
        args={[`${formatNumber(min)} ${currency}`, `${formatNumber(max)} ${currency}`]}
      />
    </TextEx>
  </MessageBox>
);

export const ComplainDialog = ({ open, onClose }) => (
  <MessageBox open={open} onClose={onClose}>
    <HtmlText
      k={'deal.complain-text'}
      size={'1.125rem'}
      args={[APP_NAME.toLowerCase()]}
    />
  </MessageBox>
);

export const SetRequisiteDialog = ({ open, onClose, onSend }) => {
  const [requisites, setRequisites] = useState('');

  const onSendHandler = () => {
    onSend(requisites);
  };

  return (
    <MessageBox open={open} onClose={onClose} noFooter>
      <HtmlText k={'deal.enter-requisites'} size={'1.125rem'} />
      <InputEx
        value={requisites}
        type={'text'}
        width={'99%'}
        onChange={setRequisites}
        autoFocus
        height={'5vh'}
        top={'1vh'}
        borderColor={Defaults.blackColor}
        bottom={'1vh'}
        textAlign={'center'}
        fontSize={'2rem'}
        onInputClick={(e) => e.stopPropagation()}
      />{' '}
      <ActionButton
        caption={'deal.action.confirm-payment'}
        width={'293px'}
        onClick={onSendHandler}
      />
    </MessageBox>
  );
};

export const HtmlText = ({
  k,
  args = [],
  size = pageFontSize,
  color,
  top,
  bottom,
  lineHeight,
  width,
}: {
  k: string;
  args?: any[];
  size?;
  top?;
  bottom?;
  width?;
  lineHeight?: number | string;
  color?: string;
}) => (
  <TextEx
    size={size}
    color={color}
    top={top}
    bottom={bottom}
    width={width}
    lineHeight={lineHeight}
    textAlign={'center'}>
    <div dangerouslySetInnerHTML={{ __html: formatString(t(k), ...args) || '' }} />
  </TextEx>
);

export const DealInfoLine = ({
  caption,
  value,
  children,
}: {
  caption: string;
  value?: any;
  children?: React.ReactNode;
}) => (
  <DealInfoLineBox>
    <PageText k={caption} width={'40%'} />
    {value ? <DealText>{value}</DealText> : undefined}
    {children}
  </DealInfoLineBox>
);

export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (min-width: 1367px) {
    min-height: 65vh;
    /* min-width: 82vh; */
    width: 65%;
  }
  margin-bottom: 30px;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  .btn-form {
    svg {
      width: 2rem;
    }
    &:not(:hover) {
      path {
        fill: #0097db !important;
      }
    }
  }
  @media (min-width: 1367px) {
    width: 35%;
    margin-left: ${(props) => px(props.left || 0)};
  }
  width: 100%;
  margin-bottom: 30px;
`;
export const DealContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.875rem;
`;
export const DealStepsPanelRoot = styled.div`
  @media (min-width: 660px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  width: 100%;

  & .step {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    align-items: center;
    height: 4.625rem;
    padding: 0.75rem;
    border: 1px solid #e9e9e9;
    @media (min-width: 660px) {
      border: 2px solid #e9e9e9;
      margin-top: 0;
    }
    margin-top: -1px;
  }
  & .step:not(:last-child) {
    @media (min-width: 660px) {
      border-right: transparent;
    }
  }
  & .step:first-child {
    border-radius: ${px(Defaults.borderRadius)} 0 0 ${px(Defaults.borderRadius)};
  }
  & .step:last-child {
    border-radius: 0 ${px(Defaults.borderRadius)} ${px(Defaults.borderRadius)} 0;
  }

  & .step.active {
    background-color: ${Defaults.mainColor};
    border-color: ${Defaults.mainColor};
    color: white;
  }
  & .step.active + .step {
    @media (min-width: 660px) {
      border-left: ${Defaults.mainColor};
    }
  }

  & .step .marker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    flex: 0 0 1.875rem;
    background-color: #f1f1f1;
    color: ${Defaults.grayColor};
  }
  & .step.active .marker {
    background-color: white;
    color: ${Defaults.mainColor};
  }
  & .step.completed .marker {
    background-color: ${Defaults.greenColor};
  }
`;
export const DealStepCaptionRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.875rem;
`;
export const DealPanelRoot = styled.div`
  position: relative;
  max-width: 100%;
  justify-items: center;

  margin-top: 1.875rem;
  @media (max-width: 660px) {
    [width='14rem'] {
      width: 100% !important;
    }
  }
  @media (min-width: 660px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: 1.875rem 3.75rem;
  }
  padding: 1.875rem 0.75rem;
  border: 2px solid ${(props) => (props.error ? Defaults.redColor : Defaults.mainColor)};
  border-radius: 1vh;

  &:after,
  &:before {
    ${(props) => (props.noArrow ? 'display: none;' : '')}
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-bottom-color: white;
    border-width: 0.75rem;
    margin-left: -0.75rem;
  }
  &:before {
    border-bottom-color: ${(props) =>
      props.error ? Defaults.redColor : Defaults.mainColor};
    border-width: 1rem;
    margin-left: -1rem;
  }
`;
export const Row = styled.div`
  @media (max-width: 660px) {
    > div {
      margin-left: 0 !important;
      margin-bottom: 1rem;
    }
  }
  @media (min-width: 660px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  ${(props) => (props.top ? `margin-top: ${px(props.top)};` : '')}
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => px(props.left || 0)};
  ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
  ${(props) => (props.center ? 'align-items: center;' : '')}
`;
export const DealText = styled(TextEx)`
  font-size: ${px(pageFontSize)};
`;
export const DealInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem 1.875rem 2rem;
`;
export const DealInfoLineBox = styled.div`
  display: flex;
  flex-direction: row;
  word-break: break-word;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${pageBorderColor};
  padding: 0.8125rem 0;
`;
export const TradeConditionBox = styled(DealInfoLineBox)`
  border: unset;
  margin-top: 0.75rem;
`;
