import { Dialog } from '@material-ui/core';
import { isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';

import { getPage, setPage } from '../../config/history';
import { t, tt } from '../../config/i18n';
import {
  newNotifications,
  Notifications,
  playNotificationSound,
} from '../../helpers/notifications';
import { getCurrentUser, UpdaterSettingsDefault } from '../../helpers/settings';
import { formatCrypto, formatString, toUpperCase } from '../../helpers/utils';
import DealsService from '../../services/DealsService';
import DisputesService from '../../services/DisputesService';
import OperationsService from '../../services/OperationsService';
import { updater } from '../../utils/api';
import { Deal, Notification } from '../../utils/types';
import {
  CloseButton,
  Defaults,
  Hr,
  HtmlText,
  LinkEx,
  onClickOutside,
  PopoverEx,
  px,
  Spacer,
  TextEx,
  UNEXPECTED_ERROR,
} from '../common';
import ActionButton from '../controls/ActionButton';
import ButtonEx from '../controls/ButtonEx';
import { Confirm, DialogContent, MessageBox } from '../controls/Dialog';
import Logo from '../controls/Logo';
import { pageFontSize } from '../controls/Page';
import Txt from '../controls/Txt';
import { NotificationBox } from '../notifications/PageNotifications';
import { buttonHeight } from './TopBar';

const TENSE_BUTTON_SIZE = '3.90rem';
interface Dispute {
  dealId: number;
  winner: 'buyer' | 'seller';
}

interface UserNotificationsProps {
  imgSize?: number | string;
}

interface State {
  opened: boolean;
  notifications: Notification[];
  unread: number[];
  unreadCount: number;
  systemMessages: Notification[];
  showDealDialog: boolean;
  showDisputeDialog: boolean;
  showSystemDialog: boolean;
  showClosedDisputeDialog: boolean;
  deal?: Deal;
  dispute?: Dispute;
}

const readTimeout = 1000;
const maxNotifications = 10;

export let notificationUpdater = (allNotifications: any): void => void 0;

export default class UserNotifications extends React.Component<UserNotificationsProps> {
  state: State = {
    opened: false,
    notifications: [],
    unread: [],
    systemMessages: [],
    unreadCount: 0,
    showDealDialog: false,
    showDisputeDialog: false,
    showClosedDisputeDialog: false,
    showSystemDialog: false,
  };

  private stopUpdate;
  private updating = false;
  private readTimer;

  private prevUnreadGroups = 0;

  componentDidMount(): void {
    this.stopUpdate = updater(
      this.update,
      UpdaterSettingsDefault.notificationsUpdaterTime,
    );
    notificationUpdater = this.updateNotifications;
  }

  componentWillUnmount(): void {
    this.stopUpdate();
  }

  updateNotifications = (allNotifications) => {
    if (!allNotifications) {
      return;
    }

    this.findNewDeal(allNotifications);
    this.findNewDispute(allNotifications);
    this.findClosedDispute(allNotifications);
    this.findSystemMessage(allNotifications);

    const unread = allNotifications
      .filter((notification) => !notification.is_read)
      .map((notification) => notification.id);

    const notifications: Notification[] = groupNotifications(allNotifications);
    if (notifications.length > maxNotifications) {
      notifications.splice(maxNotifications);
    }
    const unreadCount = notifications.reduce(
      (a, notification) => a + (notification.is_read ? 0 : 1),
      0,
    );
    this.newNotifications(allNotifications, unreadCount);

    this.setState({
      notifications,
      unreadCount,
      unread,
    });
  };

  update = () => {
    if (this.updating) {
      return;
    }
    this.updating = true;

    OperationsService.notifications({ limit: 20 })
      .then(this.updateNotifications)
      .finally(() => (this.updating = false));
  };

  findNewDeal = (notifications: Notification[]) => {
    const [notificationId, dealId] = findNewDeal(notifications);
    if (!!dealId && !isPageOfDeal(dealId)) {
      DealsService.get({ id: dealId }, (deal) => {
        if (deal) {
          this.setState({ showDealDialog: true, deal });
        }
        if (notificationId) {
          this.readNotifications([notificationId]);
        }
      });
    }
  };

  findNewDispute = (notifications: Notification[]) => {
    const [notificationId, dealId] = findNewDispute(notifications);
    if (dealId) {
      DealsService.get({ id: dealId }, (deal) => {
        if (deal) {
          if (!!deal.dispute && deal.dispute.initiator !== getCurrentUser()) {
            this.setState({ showDisputeDialog: true, deal });
          }
        }
        if (notificationId) {
          this.readNotifications([notificationId]);
        }
      });
    }
  };

  findClosedDispute = (notifications: Notification[]) => {
    const [notificationId, dispute] = findClosedDispute(notifications);
    if (dispute) {
      this.setState({ showClosedDisputeDialog: true, dispute });
      if (notificationId) {
        this.readNotifications([notificationId]);
      }
    }
  };

  findSystemMessage = (notifications: Notification[]) => {
    const messages = findSystemMessages(notifications);
    if (messages.length) {
      this.setState({ showSystemDialog: true, systemMessages: messages });
      this.readNotifications(messages.map((msg) => msg.id));
    }
  };

  readNotifications = (ids: number[]) => {
    setTimeout(() => OperationsService.readNotifications({ ids }), 1000);
  };

  newNotifications = (notifications: Notification[], unreadGroups: number) => {
    function pushNotification(obj: Record<string, number[]>, key: string, id: number) {
      let ids = obj[key];
      if (!ids) {
        ids = [];
      }
      ids.push(id);
      obj[key] = ids;
    }

    const result: Notifications = {
      deals: {},
      disputes: {},
      messages: {},
      unreadGroups,
    };
    for (const n of notifications) {
      if (!n.is_read) {
        if (n.type === 'deal' || n.type === 'cancel_deal' || n.type === 'timeout') {
          pushNotification(result.deals, n.details.id, n.id);
        } else if (n.type === 'dispute' || n.type === 'closed_dispute') {
          pushNotification(result.disputes, n.details.id, n.id);
        } else if (n.type === 'message') {
          pushNotification(result.messages, n.details.sender, n.id);
        } else if (n.type === 'system_message') {
          pushNotification(result.messages, 'system', 0);
        }
      }
    }
    newNotifications(result);

    if (result.unreadGroups > this.prevUnreadGroups) {
      playNotificationSound();
    }
    this.prevUnreadGroups = result.unreadGroups;
  };

  popoverOpen = (event) => {
    if (this.state.opened) {
      this.popoverClose();
      return;
    }
    clearInterval(this.readTimer);
    this.readTimer = setInterval(this.markRead, readTimeout);

    this.setState({ opened: true });
    onClickOutside(event.currentTarget, this.popoverClose);
  };

  popoverClose = () => {
    clearInterval(this.readTimer);
    this.setState({ opened: false });
  };

  markRead = () => {
    clearInterval(this.readTimer);
    const { unread } = this.state;
    if (unread?.length > 0) {
      const ids = (unread ?? []).filter((item) => !isNil(item));
      OperationsService.readNotifications({ ids }, () => this.update());
    }
  };

  clickAllNotifications = () => {
    this.popoverClose();
    setPage('notifications');
  };

  closeDealDialog = () => this.setState({ showDealDialog: false });
  closeDisputeDialog = () => this.setState({ showDisputeDialog: false });
  closeSystemDialog = (id: number) => () =>
    this.setState({
      showSystemDialog: false,
      systemMessages: this.state.systemMessages.filter((msg) => msg.id !== id),
    });
  closeClosedDisputeDialog = () => this.setState({ showClosedDisputeDialog: false });

  openDial = (deal?: Deal) => () => {
    this.closeDealDialog();
    if (deal) {
      setPage(`deals/${deal.id}`);
    }
  };

  openDispute = (deal?: Deal) => () => {
    this.closeDisputeDialog();
    if (deal) {
      DisputesService.dealsDisputeOpen({ id: deal.id }, () => window.location.reload());
    }
  };

  render(): React.ReactNode {
    const { imgSize } = this.props;
    const {
      opened,
      deal,
      dispute,
      unreadCount,
      notifications,
      showDealDialog,
      systemMessages,
      showDisputeDialog,
      showSystemDialog,
      showClosedDisputeDialog,
    } = this.state;

    return (
      <NotificationsContainer>
        <NotificationButtonContainer top={unreadCount ? '-.2rem' : ''}>
          <ButtonEx
            className={'Popover-btn ' + (opened ? 'active' : '')}
            hint={'notifications.title'}
            img={'/ico/top/notification.svg'}
            height={unreadCount ? TENSE_BUTTON_SIZE : buttonHeight}
            width={unreadCount ? TENSE_BUTTON_SIZE : buttonHeight}
            rounded
            boxShadow={'0px 4px 16px rgba(0, 0, 0, 0.08)'}
            border={unreadCount ? '4px solid #e7ebee58' : '0'}
            backgroundColor={Defaults.whiteColor}
            imgSize={imgSize}
            onClick={this.popoverOpen}>
            {unreadCount > 0 && (
              <RoundBox
                top={'0px'}
                absolute
                right={'-2px'}
                size={'1.5rem'}
                fontSize={'.8rem'}>
                <TextEx weight={'500'} color={Defaults.whiteColor}>
                  {unreadCount}
                </TextEx>
              </RoundBox>
            )}
          </ButtonEx>
          <Popover
            right={unreadCount ? '-5.8rem' : '-6rem'}
            className={`${opened ? 'opened' : ''}`}
            title=""
            onClick={(event) => event.stopPropagation()}>
            <PopoverArrow />
            <PopoverShadowContainer>
              <PopoverShadow>
                <Content>
                  {notifications?.length > 0 ? (
                    notifications.map((notification, i) => (
                      <NotificationBox
                        key={i}
                        isFirstElement={!i}
                        notification={notification}
                      />
                    ))
                  ) : (
                    <FixedPanel $noBorderRadius={true}>
                      <TextEx color={'gray'} weight={300}>
                        <i>
                          <Txt k={'notifications.empty'} />
                        </i>
                      </TextEx>
                    </FixedPanel>
                  )}
                </Content>
                <Hr height={1} />
                <FixedPanel $alignItems="flex-end">
                  <LinkEx
                    size={'1rem'}
                    href={'/notifications'}
                    onClick={this.clickAllNotifications}>
                    <Txt k={'notifications.all'} />
                    <Arrow />
                  </LinkEx>
                </FixedPanel>
              </PopoverShadow>
            </PopoverShadowContainer>
          </Popover>
          <DealDialog
            open={showDealDialog}
            deal={deal}
            onClose={this.closeDealDialog}
            onOpenDeal={this.openDial(deal)}
          />
          <DisputeDialog
            open={showDisputeDialog}
            deal={deal}
            onClose={this.closeDisputeDialog}
            onOpenDispute={this.openDispute(deal)}
          />
          <ClosedDisputeDialog
            open={showClosedDisputeDialog}
            dispute={dispute}
            onClose={this.closeClosedDisputeDialog}
          />
          {!!systemMessages.length &&
            systemMessages.map((message) => (
              <SystemMessageDialog
                key={message.details?.text}
                open={!!systemMessages.find((msg) => msg.id === message.id)}
                message={message.details?.text}
                onClose={this.closeSystemDialog(message.id)}
              />
            ))}
        </NotificationButtonContainer>
      </NotificationsContainer>
    );
  }
}

const NotificationButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: ${({ top }) => top};
`;

const NotificationsContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: ${buttonHeight};
  width: ${buttonHeight};
`;

const DealDialog = ({
  open,
  deal,
  onClose,
  onOpenDeal,
}: {
  open: boolean;
  deal?: Deal;
  onClose;
  onOpenDeal: () => void;
}) => (
  <Dialog open={open} onClose={onClose}>
    <CloseButton position={'absolute'} right={'1vh'} top={'1vh'} onClick={onClose} />
    {deal ? (
      <DialogContent>
        <HandshakeIcon size={'9vh'} fill={Defaults.mainColor} />
        <TextEx top={'1.875rem'} width={'100%'} size={pageFontSize} textAlign={'center'}>
          <div
            dangerouslySetInnerHTML={{
              __html: [
                formatString(
                  t('deal.apply-confirmation-1'),
                  tt('deal.open-action', deal.lot.type === 'sell' ? 'purchase' : 'sale'),
                  deal.lot.type === 'sell' ? deal.buyer.nickname : deal.seller.nickname,
                  formatCrypto(deal.amount, toUpperCase(deal.symbol)),
                  toUpperCase(deal.symbol),
                  deal.amount_currency,
                  toUpperCase(deal.lot.currency),
                  deal.lot.broker?.name,
                  deal.rate,
                ),
                '<br/><br/>',
                formatString(
                  t('deal.apply-confirmation-3'),
                  formatCrypto(
                    deal.lot.type === 'sell'
                      ? deal.seller_commission
                      : deal.buyer_commission,
                    toUpperCase(deal.symbol),
                  ),
                  toUpperCase(deal.symbol),
                ),
              ].join(''),
            }}
          />
        </TextEx>
        <Spacer height={'1.875rem'} />
        <ActionButton caption={'common.open'} onClick={onOpenDeal} />
      </DialogContent>
    ) : (
      <DialogContent>{UNEXPECTED_ERROR}</DialogContent>
    )}
  </Dialog>
);

const DisputeDialog = ({
  open,
  deal,
  onClose,
  onOpenDispute,
}: {
  open: boolean;
  deal?: Deal;
  onClose;
  onOpenDispute: () => void;
}) => (
  <Confirm
    open={open}
    submitCaption={'dispute.opened.action-open'}
    onSubmit={onOpenDispute}
    cancelCaption={'dispute.opened.action-cancel'}
    onCancel={onClose}>
    <DisputeIcon size={'9vh'} fill={Defaults.mainColor} />
    {deal ? (
      <HtmlText
        k={'dispute.opened.caption'}
        top={'1.875rem'}
        width={'7.06rem'}
        size={pageFontSize}
        textAlign={'center'}
        args={[deal.dispute ? deal.dispute.initiator : '', deal.id]}
      />
    ) : undefined}
    <HtmlText
      k={'dispute.opened.info'}
      top={'1.875rem'}
      width={'50vh'}
      size={pageFontSize}
      textAlign={'center'}
    />
    <DashedBox top={'1.875rem'} width={'50vh'}>
      <TextEx size={pageFontSize}>
        <Txt k={'dispute.opened.warn'} />
      </TextEx>
    </DashedBox>
  </Confirm>
);

const Arrow = () => (
  <ArrowRoot width="6" height="10" viewBox="0 0 6 10" fill="none">
    <path
      d="M1 9L5 5L1 1"
      stroke="#00B3FB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </ArrowRoot>
);

const SystemMessageDialog = ({
  open,
  message,
  onClose,
}: {
  open: boolean;
  message: string;
  onClose: () => void;
}) => (
  <MessageBox open={open} onClose={onClose}>
    <TextEx size={'1.2rem'} textAlign={'center'}>
      <Logo center padding={'0 0 16px 0'} />
      <TextEx size={'2rem'} weight={600}>
        {t('common.info-message')}
      </TextEx>
      <br />
      {message.split('\n').map((message) => (
        <>
          {message}
          <br />
        </>
      ))}
    </TextEx>
  </MessageBox>
);

const ClosedDisputeDialog = ({
  open,
  dispute,
  onClose,
}: {
  open: boolean;
  dispute?: Dispute;
  onClose: () => void;
}) => (
  <MessageBox open={open} onClose={onClose}>
    <TextEx size={pageFontSize} textAlign={'center'}>
      <div
        dangerouslySetInnerHTML={{
          __html:
            formatString(
              t('dispute.closed.caption'),
              dispute ? dispute.dealId : '',
              dispute ? tt('dispute.closed', dispute.winner) : '',
            ) || '',
        }}
      />
    </TextEx>
  </MessageBox>
);

const HandshakeIcon = ({ size, fill }: { size: number | string; fill: string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 512 512'} fill={fill}>
    <path
      d="M512,174.37c-0.012-7.773-2.298-15.656-7.353-22.154l-40.568-51.908c-6.795-8.628-17.134-13.021-27.466-13.038
			c-6.149,0-12.41,1.576-17.942,5.026l-39.556,24.61c-4.16,2.653-11.292,4.474-18.181,4.427c-5.352,0.023-10.524-1.048-13.939-2.735
			l-38.736-18.763c-6.248-3.008-13.213-4.241-20.217-4.253c-10.059,0.047-20.246,2.519-28.432,8.192l-4.52,3.171
			c-3.613-1.803-9.134-4.55-15.388-7.662c-7.5-3.677-16.319-5.16-25.227-5.189c-8.047,0.023-16.092,1.263-23.156,4.247
			l-26.384,11.258c-4.294,1.862-11.124,3.078-17.908,3.048c-6.784,0.029-13.602-1.187-17.896-3.048l-26.408-11.264
			c-4.817-2.054-9.966-2.932-15.069-2.938c-5.876,0.006-11.717,1.164-17.146,3.363c-5.422,2.211-10.455,5.475-14.463,9.954
			L7.965,162.56C2.636,168.511-0.011,176.005,0,183.382c-0.011,8.797,3.753,17.622,11.013,23.807l44.85,38.264l-21.276,22.195
			c-5.865,6.121-8.785,14.057-8.785,21.904c-0.011,8.349,3.305,16.779,9.873,22.986l4.316,4.101
			c4.067,3.858,8.954,6.312,14.068,7.621c-2.565,4.718-3.967,9.891-3.956,15.074c-0.011,8.355,3.305,16.779,9.861,22.993
			l-0.011-0.006l4.329,4.119c6.162,5.841,14.15,8.75,22.02,8.745c4.23,0.006,8.465-0.844,12.445-2.508
			c1.397,5.667,4.282,11.054,8.82,15.359l4.329,4.119c6.162,5.841,14.137,8.75,22.02,8.745c6.162,0.006,12.352-1.81,17.681-5.335
			c1.559,5.015,4.23,9.768,8.296,13.631l4.329,4.119c6.149,5.841,14.137,8.75,22.009,8.745c8.383,0.006,16.849-3.287,23.102-9.815
			l18.804-19.636l25.547,23.644c6.115,5.672,13.964,8.494,21.712,8.477c8.512,0.018,17.099-3.392,23.365-10.1l4.078-4.369
			c3.52-3.781,5.818-8.256,7.151-12.927l9.896,9.134c6.126,5.649,13.964,8.459,21.701,8.453c8.523,0.006,17.134-3.404,23.406-10.117
			l4.055-4.364c4.683-5.003,7.319-11.2,8.133-17.529c2.612,0.669,5.259,1.123,7.929,1.123c8.523,0,17.11-3.404,23.388-10.111
			l4.067-4.364c5.684-6.092,8.512-13.905,8.5-21.608c0-2.391-0.343-4.77-0.873-7.121c7.069-0.937,13.923-4.09,19.135-9.687
			l4.078-4.369c5.684-6.092,8.5-13.905,8.5-21.614c0.011-8.482-3.409-17.041-10.158-23.277l-7.627-7.045l68.838-76.11
			C509.138,191.876,511.989,183.056,512,174.37z M62.014,308.065c-3.647-0.011-7.214-1.315-10.001-3.956l-4.323-4.101
			c-2.991-2.828-4.468-6.591-4.48-10.455c0.012-3.624,1.309-7.162,3.98-9.949l43.576-45.473c2.839-2.962,6.627-4.445,10.513-4.45
			c3.636,0.006,7.191,1.315,10.001,3.962l4.305,4.107c0,0,4.457,6.591,4.468,10.46c-0.011,2.897-0.884,5.719-2.577,8.163
			c-0.145,0.145-47.032,49.01-47.032,49.01C67.954,307.145,65.01,308.052,62.014,308.065z M96.816,353.422
			c-2.833,2.95-6.615,4.427-10.501,4.439c-3.647-0.006-7.203-1.315-10.001-3.956l-4.329-4.114l-0.011-0.006
			c-2.967-2.816-4.457-6.591-4.457-10.455c0-3.624,1.309-7.162,3.967-9.949c0,0,58.086-60.465,58.575-61.048
			c2.776-2.67,6.353-4.049,10.047-4.055c3.647,0.006,7.203,1.309,10.001,3.956l4.329,4.119c2.978,2.816,4.457,6.586,4.468,10.455
			c-0.012,3.624-1.32,7.167-3.98,9.954L96.816,353.422z M144.423,379.13c-2.827,2.957-6.608,4.436-10.495,4.447
			c-3.647-0.006-7.203-1.315-10.001-3.962l-4.341-4.114c-2.978-2.822-4.468-6.591-4.468-10.455c0-3.234,1.1-6.371,3.205-8.983
			l40.452-42.221c2.729-2.461,6.149-3.758,9.693-3.758c3.647,0.006,7.203,1.309,10.001,3.956l4.341,4.119
			c2.967,2.816,4.457,6.586,4.468,10.449c-0.012,3.631-1.332,7.174-3.98,9.954L144.423,379.13z M221.285,374.674l-24.546,25.618
			c-2.828,2.955-6.615,4.434-10.501,4.445c-3.647-0.006-7.203-1.315-10.001-3.962l-4.329-4.114
			c-2.967-2.816-4.457-6.591-4.468-10.455c0.011-3.631,1.32-7.174,3.98-9.949l24.535-25.617c2.839-2.955,6.627-4.439,10.513-4.445
			c3.636,0.006,7.203,1.309,10.001,3.962l4.341,4.114c2.967,2.816,4.445,6.586,4.457,10.449
			C225.253,368.35,223.944,371.893,221.285,374.674z M434.523,305.183c-0.005,3.558-1.28,7.043-3.863,9.812l-4.067,4.364
			c-2.839,3.037-6.691,4.573-10.635,4.579c-3.578-0.006-7.081-1.263-9.861-3.833l-68.535-63.381l-11.851,12.689l73.358,67.959
			c3.055,2.828,4.59,6.668,4.613,10.589c-0.023,3.572-1.286,7.051-3.863,9.821l-4.055,4.364c-2.839,3.037-6.691,4.567-10.635,4.579
			c-3.578-0.011-7.092-1.274-9.85-3.828l-68.553-63.38l-11.839,12.689l60.838,56.26c2.932,2.81,4.434,6.551,4.434,10.39
			c0,3.561-1.263,7.045-3.84,9.809l-4.067,4.364c-2.828,3.037-6.691,4.573-10.646,4.584c-3.59-0.011-7.092-1.274-9.861-3.828
			l-60.716-56.056h-0.011l-0.011-0.011l-11.816,12.712l0.012,0.011l0.227,0.215l30.41,28.066c2.694,2.769,4.09,6.318,4.101,9.989
			c-0.011,3.543-1.263,7.01-3.84,9.775l-4.067,4.369c-2.839,3.037-6.679,4.561-10.612,4.573c-3.578-0.011-7.081-1.268-9.85-3.833
			l-26.477-24.517c2.304-4.52,3.59-9.425,3.59-14.347c0.011-8.349-3.305-16.767-9.861-22.986l-4.341-4.119
			c-6.149-5.836-14.126-8.75-21.997-8.739c-0.611,0-1.227,0.14-1.826,0.175c0-0.145,0.034-0.297,0.034-0.442
			c0.011-8.349-9.861-22.998-9.861-22.998l-4.329-4.107c-4.602-4.364-10.217-7.086-16.057-8.18
			c1.204-3.409,1.885-6.958,1.885-10.513c0-8.349-3.305-16.774-9.861-22.986l-4.329-4.119c-6.162-5.841-14.137-8.756-22.009-8.75
			c-0.919,0-1.826,0.186-2.74,0.268c-0.25-8.017-3.479-16.028-9.762-21.997v-0.006l-4.317-4.101
			c-6.162-5.847-14.137-8.762-22.009-8.756c-8.396-0.006-16.849,3.287-23.102,9.815l-10.286,10.734l-45.566-38.864
			c-3.275-2.804-4.91-6.679-4.922-10.658c0.011-3.334,1.146-6.598,3.566-9.308l48.079-53.845c1.908-2.141,4.73-4.078,8.035-5.423
			c3.305-1.344,7.057-2.082,10.565-2.077c3.055,0,5.922,0.553,8.215,1.542l26.396,11.263c7.441,3.136,16.08,4.422,24.762,4.45
			c8.692-0.029,17.32-1.315,24.762-4.45l26.384-11.258c4.067-1.774,10.204-2.874,16.307-2.851c6.749-0.041,13.509,1.356,17.43,3.357
			c2.49,1.239,4.852,2.415,7.045,3.514l-26.908,18.915c-8.646,6.08-13.306,15.749-13.294,25.506
			c-0.011,6.458,2.036,13.032,6.202,18.611l-0.011-0.012l3.554,4.776c6.749,8.972,17.273,13.585,27.792,13.608
			c5.94,0,11.991-1.501,17.384-4.736l16.092-9.664c3.282-2.007,8.238-3.275,13.363-3.252c6.022-0.035,12.09,1.746,15.738,4.427
			l44.519,31.917c9.908,7.098,26.046,20.264,34.989,28.549l51.529,47.643C432.975,297.433,434.511,301.256,434.523,305.183z
			 M490.038,187.146l-68.646,75.906l-31.167-28.81c-9.762-9.012-25.86-22.154-36.658-29.91l-44.507-31.917
			c-7.546-5.346-16.785-7.657-25.919-7.691c-7.773,0.023-15.604,1.717-22.364,5.742l-16.08,9.664
			c-2.467,1.483-5.375,2.24-8.396,2.246c-5.4,0.018-10.804-2.502-13.817-6.604l-3.543-4.77l-0.012-0.012
			c-1.885-2.542-2.763-5.393-2.776-8.279c0.023-4.364,2.036-8.599,5.935-11.351l47.579-33.43c4.114-2.978,11.433-5.067,18.385-5.015
			c4.829-0.023,9.442,0.948,12.601,2.508l38.735,18.763c6.604,3.171,14.057,4.468,21.555,4.486
			c9.634-0.047,19.373-2.141,27.397-7.069l39.568-24.615c2.525-1.576,5.556-2.391,8.716-2.391
			c5.341-0.011,10.693,2.409,13.724,6.359l40.568,51.908c2.339,2.973,3.694,7.167,3.694,11.514
			C494.617,179.239,492.9,184.022,490.038,187.146z"
    />
  </svg>
);

const DisputeIcon = ({ size, fill }: { size: number | string; fill: string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 480 480'} fill={fill}>
    <path
      d="M479.202,292.692l0.072-0.024l-78.808-171.952l19.112-9.512l-7.152-14.32l-18.632,9.28l-2.52-5.496
			c-1.842-4.016-6.59-5.779-10.606-3.938c-1.742,0.799-3.139,2.196-3.938,3.938l-2.248,4.912c-13.56-6.298-28.329-9.566-43.28-9.576
			h-67.2v-16h16v-16H266.25l5.752-23.016c4.416-17.673-6.33-35.58-24.003-39.997c-17.673-4.416-35.58,6.33-39.997,24.003
			c-1.312,5.25-1.312,10.743,0,15.993l5.752,23.016h-13.752v16h16v16h-67.16c-14.966,0.012-29.75,3.288-43.32,9.6l-2.248-4.912
			c-1.841-4.016-6.59-5.779-10.606-3.938c-1.742,0.799-3.139,2.196-3.938,3.938l-2.52,5.496l-18.632-9.32l-7.152,14.336
			l19.112,9.552L0.73,292.668c-0.486,1.045-0.734,2.184-0.728,3.336c0.035,30.913,25.087,55.965,56,56h80
			c30.913-0.035,55.965-25.087,56-56c-0.02-1.149-0.293-2.28-0.8-3.312l-79.024-172.6c11.487-5.332,24-8.092,36.664-8.088h67.16
			v292.688l-27.312,27.312h-28.688c-22.08,0.026-39.974,17.92-40,40c0,4.418,3.582,8,8,8h224c4.418,0,8-3.582,8-8
			c-0.026-22.08-17.92-39.974-40-40h-28.688l-27.312-27.312V112.004h67.2c12.664-0.004,25.177,2.756,36.664,8.088L288.73,292.668
			c-0.486,1.045-0.734,2.184-0.728,3.336c0.035,30.913,25.087,55.965,56,56h80c30.913-0.035,55.965-25.087,56-56
			C479.983,294.855,479.71,293.724,479.202,292.692z M136.002,336.004h-80c-19.002-0.021-35.375-13.387-39.2-32h158.4
			C171.378,322.617,155.004,335.983,136.002,336.004z M171.538,288.004H20.466l73.488-160.328c1.342,0.36,2.754,0.36,4.096,0
			L171.538,288.004z M226.618,22.532c6.23-7.392,17.273-8.333,24.665-2.103c0.759,0.64,1.463,1.344,2.103,2.103
			c3.236,4.116,4.379,9.5,3.096,14.576l-6.728,26.896H230.25l-6.728-26.896C222.239,32.032,223.383,26.648,226.618,22.532z
			 M232.002,96.004v-16h16v16H232.002z M248.002,112.004v288h-16v-288H248.002z M320.002,448.004
			c10.168,0.012,19.229,6.418,22.632,16H137.37c3.403-9.582,12.464-15.988,22.632-16H320.002z M268.69,432.004h-57.376l16-16h25.376
			L268.69,432.004z M381.938,127.7c1.321,0.465,2.77,0.405,4.048-0.168l73.552,160.472H308.466L381.938,127.7z M424.002,336.004h-80
			c-19.002-0.021-35.375-13.387-39.2-32h158.4C459.378,322.617,443.004,335.983,424.002,336.004z"
    />
  </svg>
);

function findNewDeal(
  notifications: Notification[],
): [number | undefined, string | undefined] {
  for (const notification of notifications) {
    if (notification.type === 'deal' && !notification.is_read) {
      if (notification.details.is_owner && notification.details.state === 'proposed') {
        return [notification.id, notification.details.id];
      }
    }
  }
  return [undefined, undefined];
}

function findNewMessages(notifications: Notification[]): Notification[] {
  const result: Notification[] = [];
  for (const notification of notifications) {
    if (notification.type === 'message' && !notification.is_read) {
      result.push(notification);
    }
  }
  return result;
}

function findNewDispute(
  notifications: Notification[],
): [number | undefined, string | undefined] {
  for (const notification of notifications) {
    if (notification.type === 'dispute' && !notification.is_read) {
      if (notification.details.state !== 'deleted') {
        return [notification.id, notification.details.id];
      }
    }
  }
  return [undefined, undefined];
}

function findSystemMessages(notifications: Notification[]): Notification[] {
  const result: Notification[] = [];
  for (const notification of notifications) {
    if (notification.type === 'system_message' && !notification.is_read) {
      result.push(notification);
    }
  }
  return result;
}

function findClosedDispute(
  notifications: Notification[],
): [number | undefined, Dispute | undefined] {
  for (const notification of notifications) {
    if (notification.type === 'closed_dispute' && !notification.is_read) {
      return [
        notification.id,
        { dealId: notification.details.id, winner: notification.details.winner },
      ];
    }
  }
  return [undefined, undefined];
}

function isPageOfDeal(dealId: string): boolean {
  const page = getPage();
  return page.name === 'deal' && page.param === dealId;
}

const ArrowRoot = styled.svg`
  position: relative;
  margin-left: 14px;
  top: -1px;
`;

const Popover = styled(PopoverEx)`
  width: 320px;
  padding-top: 32px;
  right: ${({ right }) => right};
  left: auto;
  overflow: hidden;
  background-color: transparent;
  box-shadow: none;

  @media (min-width: 1440px) {
    width: 420px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -2vh;
    left: 1.875rem;
    border-bottom: 1px solid ${Defaults.activeColor};
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    z-index: 10;
  }
`;
const Header = styled.div`
  min-width: 18rem;
  padding: 1.875rem;
  font-size: 1.2rem;
  color: ${Defaults.textColor};
`;
const Content = styled.div`
  height: auto;
`;

const FixedPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 3.75rem;
  padding: 0 22px;
  align-items: ${({ $alignItems }) => $alignItems || 'center'};
  justify-content: center;
  background-color: ${Defaults.whiteColor};
  border-bottom-left-radius: ${({ $noBorderRadius }) => ($noBorderRadius ? 0 : '8px')};
  border-bottom-right-radius: ${({ $noBorderRadius }) => ($noBorderRadius ? 0 : '8px')};
  border-top-right-radius: ${({ $noBorderRadius }) => ($noBorderRadius ? '8px' : 0)};
  border-top-left-radius: ${({ $noBorderRadius }) => ($noBorderRadius ? '8px' : 0)};
`;

const DashedBox = styled.div`
  margin-top: ${(props) => px(props.top || 0)};
  width: ${(props) => px(props.width)};
  border: 1px dashed silver;
  border-radius: ${px(Defaults.borderRadius)};
  text-align: center;
  padding: 1.875rem;
`;

const PopoverArrow = styled.div`
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  top: 13px;
  right: 7rem;

  @media (min-width: 1440px) {
    right: 7.2rem;
  }
`;

const PopoverShadowContainer = styled.div`
  padding: 1rem;
  padding-top: 0;
`;

const PopoverShadow = styled.div`
  -webkit-box-shadow: 0px 0px 8px 6px rgba(214, 214, 214, 0.2);
  -moz-box-shadow: 0px 0px 8px 6px rgba(214, 214, 214, 0.2);
  box-shadow: 0px 0px 8px 6px rgba(214, 214, 214, 0.2);
  border-radius: 8px;
`;

export const RoundBox = styled.div`
  top: ${(props) => px(props.top)};
  left: ${(props) => px(props.left)};
  margin-left: ${(props) => px(props.left)};
  right: ${(props) => px(props.right)};
  bottom: ${(props) => px(props.bottom)};
  ${(props) => (props.absolute ? 'position: absolute;' : '')}
  min-width: ${(props) => px(props.size)};
  height: ${(props) => px(props.size)};
  border-radius: 50%;
  background-color: ${Defaults.redColor};
  color: white;
  text-align: center;
  font-size: ${(props) => px(props.fontSize)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

function groupNotifications(notifications: Notification[]): Notification[] {
  const result: Notification[] = [];
  for (const n1 of notifications) {
    const n2 = findNotification(n1, result);
    if (!n2) {
      result.push(n1);
    } else {
      n2.is_read = n2.is_read && n1.is_read;
    }
  }
  return result;
}

function findNotification(
  notification: Notification,
  notifications: Notification[],
): Notification | undefined {
  for (const n of notifications) {
    if (similarNotification(notification, n)) {
      return n;
    }
  }
  return;
}

function similarNotification(n1, n2: Notification): boolean {
  if (n1.type !== n2.type) {
    return false;
  }
  switch (n1.type) {
    case 'deal':
      if (n1.details.id !== n2.details.id) {
        return false;
      }
      break;
    case 'message':
      if (!!n1.details.media || n1.details.sender !== n2.details.sender) {
        return false;
      }
      break;
    default:
      return false;
  }
  return true;
}
