import { action, makeAutoObservable, observable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

import { Bid } from '../components/bids/BidCard';
import { P2PRow } from '../components/p2p';
import BrokersService from '../services/BrokersService';
import { BankType, DealShort } from '../utils/types';

export type PersistedBrokers = Record<string, BankType>;

let shadowBrokers: PersistedBrokers = {};

export const setShadowBrokers = (persistedBrokers) => {
  shadowBrokers = persistedBrokers;
};

export class Brokers {
  @observable.deep private _persistedBrokers: PersistedBrokers = {};
  @observable.deep private _filteredBrokers: PersistedBrokers = {};

  constructor({
    localStoragePropName,
    persistedBrokers,
  }: {
    localStoragePropName?: string;
    persistedBrokers: PersistedBrokers;
  }) {
    makeAutoObservable(this);

    if (localStoragePropName) {
      makePersistable(this, {
        name: localStoragePropName,
        expireIn: 86400000, // One day in milliseconds
        removeOnExpiration: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        properties: ['_persistedBrokers'],
        storage: window.localStorage,
      });
    }

    this._persistedBrokers = persistedBrokers;
    this.fetchPersistedBrokers();
  }

  get persistedBrokers() {
    return this._persistedBrokers;
  }

  get filteredBrokers() {
    return this._filteredBrokers;
  }

  @action setPersistedBrokers = (persistedBrokers: PersistedBrokers) => {
    this._persistedBrokers = persistedBrokers;
  };

  @action setFilteredBrokers = (filteredBrokers: PersistedBrokers) => {
    this._filteredBrokers = filteredBrokers;
  };

  fetchPersistedBrokers = async () => {
    const brokers = await BrokersService.paymentMethods({
      with_available_currencies: true,
      with_logo: false,
    });

    brokers.map((broker) => {
      if (!shadowBrokers[broker.id]) {
        shadowBrokers = {
          ...shadowBrokers,
          [broker.id]: { ...broker, ...(this._persistedBrokers[broker.id] ?? {}) },
        };
      }
    });

    this.setPersistedBrokers({ ...shadowBrokers });
    this.setFilteredBrokers({ ...shadowBrokers });
  };

  filterBrokers = async (currency?: string) => {
    this.setFilteredBrokers({});

    let result = Object.values(this._persistedBrokers).filter((broker) =>
      currency ? broker.available_currencies?.includes(currency) : true,
    );

    const brokersNeedUpdate: string[] = [];

    if (currency) {
      for (const broker of result) {
        !this._persistedBrokers[broker.id].logo && brokersNeedUpdate.push(broker.id);
      }
    }

    await this.updateBrokers(brokersNeedUpdate);

    result = result.map((broker) => this._persistedBrokers[broker.id]);

    this.setFilteredBrokers({
      ...result.reduce((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      }, {}),
    });

    return Promise.resolve();
  };

  updateBrokers = async (ids: string[]) => {
    if (ids.length) {
      const brokersData = await BrokersService.getBrokersByIds({ ids });
      shadowBrokers = {
        ...shadowBrokers,
        ...brokersData.reduce((acc, broker) => {
          acc[broker.id] = { ...shadowBrokers[broker.id], ...broker };
          return acc;
        }, {}),
      };
    }

    this.setPersistedBrokers({ ...shadowBrokers });
  };

  loadBrokersLogoAndName = async (
    data: DealShort[] | Bid[] | P2PRow[],
    dealIndex = 0,
  ) => {
    const brokerId = data[dealIndex]?.brokerId;

    if (brokerId && !shadowBrokers[brokerId]) {
      const brokerData = await BrokersService.getBrokerById(brokerId);
      shadowBrokers = {
        ...shadowBrokers,
        [brokerId]: brokerData,
      };
    }
    if (data.length !== dealIndex + 1) {
      this.loadBrokersLogoAndName(data, ++dealIndex);
    } else {
      this.setPersistedBrokers({ ...shadowBrokers });
    }
  };

  async clearPersistedData() {
    await clearPersistedStore(this);
  }
}

export const BrokersStore = new Brokers({
  localStoragePropName: 'brokers',
  persistedBrokers: {},
});
