import { CircularProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { setPage } from '../../config/history';
import { onLanguageChange, t, tt } from '../../config/i18n';
import {
  listenNotifications,
  Notifications,
  stopListenNotifications,
} from '../../helpers/notifications';
import { getCurrentUser, UpdaterSettingsDefault } from '../../helpers/settings';
import {
  formatCrypto,
  formatNumber,
  formatString,
  nvl,
  roundCrypto,
  roundFloat,
  toUpperCase,
} from '../../helpers/utils';
import { usePersistentCallback } from '../../hooks/usePersistantCallback';
import DealsService from '../../services/DealsService';
import DisputesService from '../../services/DisputesService';
import LotsService from '../../services/LotsService';
import OperationsService from '../../services/OperationsService';
import UsersService from '../../services/UsersService';
import { updater } from '../../utils/api';
import { dealTypes } from '../../utils/types';
import { registerHandlerOnType, WS_SIGNALS } from '../../utils/websocket';
import {
  CloseButton,
  CloseButtonBox,
  ContentRoot,
  ContentRow,
  Defaults,
  Hr,
  PageTitle,
  TextEx,
  UserLink,
} from '../common';
import ActionButton from '../controls/ActionButton';
import Amount from '../controls/Amount';
import ChatEx, { ChatMessage, getChatMessages, sendChatMessage } from '../controls/Chat';
import CheckboxEx from '../controls/CheckboxEx';
import {
  ComplainButton,
  PageActions,
  PageCaption,
  pageFontSize,
  PageRoot,
} from '../controls/Page';
import { UserDealsRating, UserReviews } from '../controls/User';
import { TelegramIcon } from '../home/home-common';
import PageNotFound from '../PageNotFound';
import { PaymentMethodBox } from '../paymentMethods';
import { RoundBox } from '../topBar/UserNotifications';
import {
  Column,
  ComplainDialog,
  ConfirmCancelDialog,
  ConfirmOpenDialog,
  ConfirmPaymentDialog,
  ConfirmSendCoinsDialog,
  Deal,
  DealApplyPanel,
  DealCanceledPanel,
  DealClosePanel,
  DealContent,
  DealDonePanel,
  DealInfoContent,
  DealInfoLine,
  DealOpeningPanel,
  DealPaymentPanel,
  DealSendCoinsPanel,
  DealSendRequisitesPanel,
  DealStep,
  DealStepCaption,
  DealStepsPanel,
  DealText,
  DealWaitCoinsPanel,
  DealWaitPaymentPanel,
  DealWaitRequisitesPanel,
  filterDealSteps,
  InfoColumn,
  MainColumn,
  SetRequisiteDialog,
  TradeConditionBox,
  WrongSubAmountDialog,
} from './DealGeneral';

// Types
interface DealState {
  deal: Deal;
  steps?: DealStep[];
  currentStep?: DealStep;
  amount?: number;
  subAmount?: number;
  requisite?: string;
  requisiteIsCorrect?: boolean;
  lastRequisites?: string[];
  notFound: boolean;
  isLoadingMain: boolean;
}

interface ChatState {
  messages: ChatMessage[];
  showChat: boolean;
  newMessages?: number[];
}

interface DialogState {
  showWrongSubAmount: boolean;
  showConfirmOpen: boolean;
  showConfirmCancel: boolean;
  showConfirmPayment: boolean;
  showConfirmSendCoinsNoAgreement: boolean;
  showConfirmSendCoins: boolean;
  showComplainDialog: boolean;
  showSetRequisiteDialog: boolean;
  isLoadingButton: boolean;
}

// Constants
const defaultDeal: Deal = {
  id: '',
  type: 'purchase',
  state: 'new',
  partner: {
    name: '',
    verified: false,
  },
  currency: '',
  subCurrency: '',
  paymentMethod: { id: '', name: '' },
  rate: 0,
  limitMin: 0,
  limitMax: 0,
  voted: false,
  isBidOwner: false,
};

// Custom hooks
const useDealState = () => {
  const [state, setState] = useState<DealState>({
    deal: defaultDeal,
    steps: undefined,
    currentStep: undefined,
    amount: undefined,
    subAmount: undefined,
    requisite: undefined,
    requisiteIsCorrect: false,
    lastRequisites: undefined,
    notFound: false,
    isLoadingMain: true,
  });

  const updateState = usePersistentCallback((updates: Partial<DealState>) => {
    setState((prev) => ({ ...prev, ...updates }));
  }, []);

  return { state, updateState };
};

const useChatState = () => {
  const [state, setState] = useState<ChatState>({
    messages: [],
    showChat: false,
    newMessages: undefined,
  });

  const updateState = usePersistentCallback((updates: Partial<ChatState>) => {
    setState((prev) => ({ ...prev, ...updates }));
  }, []);

  return { state, updateState };
};

const useDialogState = () => {
  const [state, setState] = useState<DialogState>({
    showWrongSubAmount: false,
    showConfirmOpen: false,
    showConfirmCancel: false,
    showConfirmPayment: false,
    showConfirmSendCoinsNoAgreement: false,
    showConfirmSendCoins: false,
    showComplainDialog: false,
    showSetRequisiteDialog: false,
    isLoadingButton: false,
  });

  const updateState = usePersistentCallback((updates: Partial<DialogState>) => {
    setState((prev) => ({ ...prev, ...updates }));
  }, []);

  return { state, updateState };
};

const useDealActions = (
  state: DealState,
  updateState: (updates: Partial<DealState>) => void,
  props: RouteComponentProps<{ id: string }>,
) => {
  const lockActionsRef = useRef(false);
  const stopUpdateBidRef = useRef<() => void>();
  const stopUpdateDealRef = useRef<() => void>();

  const getDeal = usePersistentCallback(
    (id: string): Promise<Deal | undefined> =>
      new Promise((resolve) => {
        DealsService.get({ id, with_merchant: true }, (response) => {
          if (!response) {
            return resolve(undefined);
          }
          const currentUser = getCurrentUser();
          const partner =
            response.buyer.nickname === currentUser ? response.seller : response.buyer;
          const type =
            response.lot.user === currentUser
              ? response.lot.type === 'buy'
                ? 'purchase'
                : 'sale'
              : response.lot.type === 'sell'
              ? 'purchase'
              : 'sale';
          const deal: Deal = {
            id: response.id,
            bidId: response.lot.id,
            type,
            dealType: response.type,
            state: response.state,
            partner: {
              name: partner.nickname,
              deals: partner.deals.deals,
              rating: partner.rating,
              likes: partner.deals.likes,
              dislikes: partner.deals.dislikes,
              verified: partner.verified,
              duration: partner.registered,
              status: partner.online ? 'online' : 'offline',
            },
            confirmedAt: new Date(response.confirmed_at),
            currency: nvl(toUpperCase(response.lot.symbol)),
            subCurrency: nvl(toUpperCase(response.lot.currency)),
            paymentMethod: nvl(response.lot.broker),
            amount: response.amount,
            subAmount: response.amount_currency,
            rate: nvl(
              response.rate,
              response.amount_currency !== 0
                ? response.amount / response.amount_currency
                : 0,
            ),
            limitMin: nvl(response.lot.limit_from, 0),
            limitMax: nvl(response.lot.limit_to, 0),
            tradeCondition: nvl(response.lot.details),
            requisite: response.requisite === 'None' ? undefined : response.requisite,
            purchaseCommission: response.buyer_commission,
            saleCommission: response.seller_commission,
            expireIn:
              response.expire_in === null
                ? undefined
                : response.expire_in > 0
                ? response.expire_in
                : 0,
            voted: response.voted,
            cancelReason: nvl(response.cancel_reason, 'timeout'),
            dispute: response.dispute
              ? {
                  initiator: response.dispute.initiator,
                  opponent: nvl(response.dispute.opponent),
                }
              : undefined,
            isBidOwner: response.lot.user === currentUser,
            mask: response.mask,
            requiredMask: response.required_mask,
          };
          resolve(deal);
        });
      }),
    [],
  );

  const newDealByBid = usePersistentCallback(
    (id: string): Promise<Deal | undefined> => {
      return new Promise((resolve) => {
        LotsService.get({ id, actual_limits: true }, (bid) => {
          if (!bid) {
            return resolve(undefined);
          }
          UsersService.profile({ id: bid.user }, (partner) => {
            const rate = nvl(bid.rate, 0);
            const dealByBid: Deal = {
              id: state.deal.id,
              bidId: id,
              type: bid.type === 'sell' ? 'purchase' : 'sale',
              state: 'new',
              partner: {
                name: partner.nickname,
                deals: partner.deals.deals,
                rating: partner.rating,
                likes: partner.deals.likes,
                dislikes: partner.deals.dislikes,
                verified: partner.verified,
                duration: partner.registered,
                status: partner.online ? 'online' : 'offline',
              },
              currency: nvl(toUpperCase(bid.symbol)),
              subCurrency: nvl(toUpperCase(bid.currency)),
              paymentMethod: nvl(bid.broker),
              rate,
              limitMin: nvl(bid.limit_from, 0),
              limitMax: nvl(bid.limit_to, 0),
              tradeCondition: nvl(bid.details),
              voted: false,
              isBidOwner: bid.user === getCurrentUser(),
            };
            resolve(dealByBid);
          });
        });
      });
    },
    [state.deal.id],
  );

  const updateDeal = usePersistentCallback(
    (spinnerNeeded?: boolean) => {
      updateState({ isLoadingMain: spinnerNeeded ?? true });
      const id = props.match.params.id;
      const p = id.startsWith('bid$') ? newDealByBid(id.substr(4)) : getDeal(id);
      p?.then((deal) => {
        if (deal) {
          let steps: DealStep[] | undefined;
          let currentStep: DealStep | undefined;
          if (deal.state !== 'deleted') {
            if (deal.type === 'purchase') {
              steps = filterDealSteps(
                deal.isBidOwner ? 'apply' : 'open',
                'wait-requisites',
                'payment',
                'wait-coins',
                'close',
              );
              switch (deal.state) {
                case 'new':
                  currentStep = 'open';
                  break;
                case 'proposed':
                  currentStep = deal.isBidOwner ? 'apply' : 'wait-requisites';
                  break;
                case 'confirmed':
                  currentStep = !deal.requisite ? 'wait-requisites' : 'payment';
                  break;
                case 'paid':
                  currentStep = 'wait-coins';
                  break;
                case 'closed':
                  currentStep = deal.voted ? 'done' : 'close';
                  break;
              }
            } else if (deal.type === 'sale') {
              steps = [
                deal.isBidOwner ? 'apply' : 'open',
                'send-requisites',
                'wait-payment',
                'send-coins',
                'close',
              ];
              switch (deal.state) {
                case 'new':
                  currentStep = 'open';
                  break;
                case 'proposed':
                  currentStep = deal.isBidOwner
                    ? 'apply'
                    : !deal.requisite
                    ? 'send-requisites'
                    : 'wait-payment';
                  break;
                case 'confirmed':
                  currentStep = !deal.requisite ? 'send-requisites' : 'wait-payment';
                  break;
                case 'paid':
                  currentStep = 'send-coins';
                  break;
                case 'closed':
                  currentStep = deal.voted ? 'done' : 'close';
                  break;
              }
            }
            if (currentStep === 'send-requisites') {
              OperationsService.dealLastRequisites(
                { paymentMethod: deal.paymentMethod?.name },
                (lastRequisites) => updateState({ lastRequisites }),
              );
            }
          }
          if (deal.expireIn !== undefined) {
            deal.expireTime = new Date(Date.now() + deal.expireIn * 1000);
          }
          updateState({
            deal,
            steps,
            currentStep,
            amount: deal.amount,
            subAmount: deal.subAmount,
          });
        } else {
          updateState({ notFound: true });
        }
      })
        .catch(() => updateState({ notFound: true }))
        .finally(() => {
          lockActionsRef.current = false;
          updateState({ isLoadingMain: false });
        });
    },
    [props.match.params.id, getDeal, newDealByBid, updateState],
  );

  const runDeal = usePersistentCallback(() => {
    if (!lockActionsRef.current) {
      lockActionsRef.current = true;
      DealsService.run({ id: state.deal.id }, (response) => {
        if (!!response && !!response.success) {
          updateDeal();
        }
      }).finally(() => (lockActionsRef.current = false));
    }
  }, [state.deal.id, updateDeal]);

  const updateBid = usePersistentCallback(() => {
    const { deal } = state;
    if (!deal.bidId) return;

    LotsService.get({ id: deal.bidId }, (bid) => {
      if (bid?.rate != null) {
        updateState({ deal: { ...deal, rate: bid.rate } });
      }
    });
  }, [state.deal.bidId, state.deal, updateState]);

  return {
    lockActionsRef,
    stopUpdateBidRef,
    stopUpdateDealRef,
    runDeal,
    updateDeal,
    updateBid,
  };
};

const useChat = (
  state: DealState,
  chatState: ChatState,
  updateChatState: (updates: Partial<ChatState>) => void,
  updateDeal: () => void,
) => {
  const updateMessages = usePersistentCallback(() => {
    if (!chatState.showChat) return;

    const { deal } = state;
    getChatMessages(deal.partner.name).then((messages) => {
      if (!messages) return;

      if (messages.length === 0) {
        messages.push({
          type: 'hint',
          date: new Date(),
          text: formatString(
            t('deal.chat.welcome'),
            t(`deal.chat.welcome-${deal.type === 'sale' ? 'buyer' : 'seller'}`),
          ),
        });
      }
      if (chatState.newMessages?.length) {
        OperationsService.readNotifications({ ids: chatState.newMessages });
      }
      updateChatState({ messages, newMessages: [] });
    });
  }, [
    chatState.showChat,
    chatState.newMessages,
    state.deal.partner.name,
    state.deal.type,
    updateChatState,
  ]);

  const openChat = usePersistentCallback(() => {
    updateChatState({ showChat: true });
  }, [updateChatState, updateMessages]);

  const sendMessage = usePersistentCallback(
    async (message: ChatMessage) => {
      const { deal } = state;
      message.currency = deal.currency;

      try {
        const success = await sendChatMessage(message);
        if (success) {
          updateChatState({ messages: [...chatState.messages, message] });
          updateMessages();
        }
      } catch (error) {}
    },
    [state.deal, chatState.messages, updateChatState, updateMessages],
  );

  useEffect(() => {
    chatState.showChat && updateMessages();
  }, [chatState.showChat]);

  return {
    updateMessages,
    openChat,
    sendMessage,
  };
};

const useNotifications = (
  state: DealState,
  chatState: ChatState,
  updateDealState: (updates: Partial<DealState>) => void,
  updateChatState: (updates: Partial<ChatState>) => void,
  updateDeal: () => void,
  updateMessages: () => void,
) => {
  const listenNotificationsCallback = usePersistentCallback(
    (notifications: Notifications) => {
      function readNotifications(ids: number[]) {
        setTimeout(() => OperationsService.readNotifications({ ids }), 1000);
      }

      const { deal } = state;
      if (notifications.deals?.[deal.id]) {
        updateDeal();
        readNotifications(notifications.deals[deal.id]);
      }
      if (notifications.disputes?.[deal.id]) {
        updateDeal();
      }
      let newMessage2: number[] = [];
      if (notifications.messages?.[deal.partner.name]) {
        const messageNotifications = notifications.messages[deal.partner.name];
        newMessage2 = messageNotifications;
        if (chatState.showChat) {
          updateMessages();
          readNotifications(messageNotifications);
        }
      }
      updateChatState({ newMessages: newMessage2 });
    },
    [
      state.deal.id,
      state.deal.partner.name,
      chatState.showChat,
      updateDealState,
      updateChatState,
      updateDeal,
      updateMessages,
    ],
  );

  return { listenNotificationsCallback };
};

// Main component
const PageDealFunctional: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const { state: dealState, updateState: updateDealState } = useDealState();
  const { state: chatState, updateState: updateChatState } = useChatState();
  const { state: dialogState, updateState: updateDialogState } = useDialogState();

  const {
    lockActionsRef,
    stopUpdateBidRef,
    stopUpdateDealRef,
    runDeal,
    updateDeal,
    updateBid,
  } = useDealActions(dealState, updateDealState, props);

  const { updateMessages, openChat, sendMessage } = useChat(
    dealState,
    chatState,
    updateChatState,
    updateDeal,
  );

  const { listenNotificationsCallback } = useNotifications(
    dealState,
    chatState,
    updateDealState,
    updateChatState,
    updateDeal,
    updateMessages,
  );

  useEffect(() => {
    onLanguageChange(() => updateDealState({}));
    listenNotifications(listenNotificationsCallback);
    updateDeal();
    updateMessages();
    registerHandlerOnType(WS_SIGNALS.UPDATE_DEAL, 'message', updateMessages);

    stopUpdateBidRef.current = updater(updateBid);
    stopUpdateDealRef.current = updater(
      () => updateDeal(false),
      UpdaterSettingsDefault.generalUpdaterTime,
      false,
    );

    return () => {
      stopListenNotifications(listenNotificationsCallback);
      stopUpdateBidRef.current?.();
      stopUpdateDealRef.current?.();
    };
  }, [props.match.params.id]);

  useEffect(() => {
    if (props.match.params.id !== props.location.pathname) {
      updateDeal();
    }
  }, [props.match.params.id, props.location.pathname]);

  // Event handlers
  const onAmountChange = useCallback(
    (amount?: number) => {
      const { deal } = dealState;
      updateDealState({
        amount,
        subAmount: amount ? roundFloat(nvl(amount, 0) * deal.rate, 2) : undefined,
      });
    },
    [dealState.deal, updateDealState],
  );

  const onSubAmountChange = useCallback(
    (subAmount?: number) => {
      const { deal } = dealState;
      updateDealState({
        subAmount,
        amount:
          !!subAmount && deal.rate !== 0
            ? roundCrypto(nvl(subAmount, 0) / deal.rate, deal.currency)
            : undefined,
      });
    },
    [dealState.deal, updateDealState],
  );

  const onRequisitesChange = useCallback(
    (requisite?: string) => {
      const requisiteIsCorrect = /(?:\D*\d){7,}/.test(requisite ?? '');
      updateDealState({ requisite, requisiteIsCorrect });
    },
    [updateDealState],
  );

  const validateSubAmount = useCallback(() => {
    const { deal, subAmount } = dealState;
    if (deal.state === 'new') {
      if (!subAmount || subAmount < deal.limitMin || subAmount > deal.limitMax) {
        updateDialogState({ showWrongSubAmount: true });
        return;
      }
      updateDialogState({ showConfirmOpen: true });
    }
  }, [dealState.deal, dealState.subAmount, updateDialogState]);

  const openDeal = useCallback(() => {
    if (!lockActionsRef.current) {
      const { deal, subAmount } = dealState;
      lockActionsRef.current = true;
      DealsService.new(
        {
          deal: {
            lot_id: deal.bidId ?? deal.id,
            amount_currency: subAmount,
          },
        },
        (newDeal) => {
          if (newDeal) {
            updateDealState({
              deal: { ...deal, state: 'proposed', id: newDeal.id },
            });
            setPage(`deals/${newDeal.id}`);
          }
        },
      ).catch(() => {
        lockActionsRef.current = false;
      });
      updateDialogState({ showConfirmOpen: false });
    }
  }, [dealState.deal, dealState.subAmount, updateDealState, updateDialogState]);

  const applyDeal = useCallback(() => {
    runDeal();
  }, [runDeal]);

  const confirmCancelDeal = useCallback(
    () => updateDialogState({ showConfirmCancel: true }),
    [updateDialogState],
  );

  const cancelDeal = useCallback(() => {
    if (!lockActionsRef.current) {
      lockActionsRef.current = true;
      DealsService.cancel({ id: dealState.deal.id }, (response) => {
        if (!!response && !!response.success) {
          updateDeal();
        }
      }).finally(() => (lockActionsRef.current = false));
      updateDialogState({ showConfirmCancel: false });
    }
  }, [dealState.deal.id, updateDeal, updateDialogState]);

  const confirmRequisite = useCallback(() => {
    if (!lockActionsRef.current) {
      const { deal, requisite } = dealState;
      if (requisite && deal.bidId) {
        lockActionsRef.current = true;
        LotsService.get({ id: deal.bidId }, (bid) => {
          DealsService.setRequisite({ id: deal.id, requisite }, (response) => {
            if (response && response.success) {
              updateDeal();
            }
          }).finally(() => (lockActionsRef.current = false));
        });
      }
    }
  }, [dealState.deal, dealState.requisite, updateDeal]);

  const confirmPayment = useCallback(
    () => updateDialogState({ showConfirmPayment: true }),
    [updateDialogState],
  );

  const confirmPayment2 = useCallback(() => {
    runDeal();
    updateDialogState({ showConfirmPayment: false });
  }, [runDeal, updateDialogState]);

  const confirmSendCoins = useCallback(
    () => updateDialogState({ showConfirmSendCoins: true }),
    [updateDialogState],
  );

  const confirmSendCoinsNoAggreement = useCallback(
    () => updateDialogState({ showConfirmSendCoinsNoAgreement: true }),
    [updateDialogState],
  );

  const sendCoins = useCallback(() => {
    runDeal();
    updateDialogState({ showConfirmSendCoins: false });
  }, [runDeal, updateDialogState]);

  const openDispute = useCallback(() => {
    if (!lockActionsRef.current) {
      lockActionsRef.current = true;
      DisputesService.dealsDisputeOpen({ id: dealState.deal.id }, () =>
        updateDeal(),
      ).finally(() => (lockActionsRef.current = false));
    }
  }, [dealState.deal.id, updateDeal]);

  const voteUser = useCallback(
    (method) => {
      if (!lockActionsRef.current) {
        const { deal } = dealState;
        lockActionsRef.current = true;
        UsersService.dealVoteUser(
          { dealId: deal.id, user: deal.partner.name, method },
          (response) => {
            if (!!response && !!response.success) {
              updateDeal();
            }
          },
        ).finally(() => (lockActionsRef.current = false));
      }
    },
    [dealState.deal, updateDeal],
  );

  const complainConditions = useCallback(
    () => updateDialogState({ showComplainDialog: true }),
    [updateDialogState],
  );

  const onSendWithoutApprovement = useCallback(
    () => updateDialogState({ showSetRequisiteDialog: true }),
    [updateDialogState],
  );

  const sendRequisiteUpdate = useCallback(
    (requisite: string) => {
      DealsService.setRequisite({ id: dealState.deal.id, requisite }).then(() => {
        updateDialogState({ showSetRequisiteDialog: false });
        updateDeal();
      });
    },
    [dealState.deal.id, updateDialogState, updateDeal],
  );

  const sendNoAgreement = useCallback(() => {
    updateDialogState({ isLoadingButton: true });
    const { deal } = dealState;
    DealsService.sendNoAgreement({ id: deal.id }, () => {
      updateDialogState({
        showConfirmSendCoinsNoAgreement: false,
        isLoadingButton: true,
      });
      updateDeal();
    });
  }, [dealState.deal, updateDialogState, updateDeal]);

  // Early returns
  if (dealState.notFound) {
    return <PageNotFound />;
  }

  if (dealState.isLoadingMain) {
    return <CircularProgress />;
  }

  // Render helpers
  const currentStepIndex =
    !!dealState.steps && !!dealState.currentStep
      ? dealState.currentStep === 'done'
        ? dealState.steps.length
        : dealState.steps.indexOf(dealState.currentStep)
      : -1;

  // Render
  return (
    <ContentRoot>
      <ContentRow height={'3.75rem'}>
        <PageTitle
          title={dealState.deal.state !== 'new' ? 'deal.title' : 'deal.title-new'}
          args={[dealState.deal.id]}
        />
      </ContentRow>
      <ContentRow flex={'1 1 auto'} wrapBreakpoint={'1367px'}>
        <MainColumn>
          {dealState.deal.currency ? (
            <PageRoot minWidth={'7.06rem'}>
              <PageCaption
                title={'deal.sub-title'}
                args={[
                  tt('deals.type', dealState.deal.type),
                  formatCrypto(dealState.amount || 0, dealState.deal.currency),
                  dealState.deal.currency,
                  dealState.subAmount || 0,
                  dealState.deal.subCurrency,
                  dealState.deal.paymentMethod?.name,
                  t(
                    dealState.deal.type === 'sale'
                      ? 'deal.sub-title-seller'
                      : 'deal.sub-title-buyer',
                  ),
                  dealState.deal.partner.name,
                  dealState.deal.dealType &&
                  ![dealTypes.fast, dealTypes.plain].includes(dealState.deal.dealType)
                    ? `(${t('deal.deal-type.tip')}: ${t(
                        `deal.deal-type.${dealState.deal.dealType}`,
                      )})`
                    : '',
                ]}></PageCaption>

              <Hr />
              {!!dealState.steps && dealState.currentStep !== undefined ? (
                <DealContent>
                  <DealStepsPanel steps={dealState.steps} current={currentStepIndex} />
                  <DealStepCaption
                    n={currentStepIndex}
                    step={dealState.currentStep}
                    req={dealState.deal.requisite || ''}
                  />
                  {
                    {
                      open: (
                        <DealOpeningPanel
                          deal={dealState.deal}
                          disabled={lockActionsRef.current}
                          amount={dealState.amount}
                          onAmountChange={onAmountChange}
                          subAmount={dealState.subAmount}
                          onSubAmountChange={onSubAmountChange}
                          onOpen={validateSubAmount}
                        />
                      ),
                      apply: (
                        <DealApplyPanel
                          deal={dealState.deal}
                          disabled={lockActionsRef.current}
                          onApply={applyDeal}
                          onCancel={confirmCancelDeal}
                        />
                      ),
                      'wait-requisites': (
                        <DealWaitRequisitesPanel
                          onSendWithoutApprovement={onSendWithoutApprovement}
                          deal={dealState.deal}
                        />
                      ),
                      'send-requisites': (
                        <DealSendRequisitesPanel
                          deal={dealState.deal}
                          disabled={
                            lockActionsRef.current || !dealState.requisiteIsCorrect
                          }
                          requisite={dealState.requisite}
                          lastRequisites={dealState.lastRequisites}
                          onChange={onRequisitesChange}
                          onAccept={confirmRequisite}
                          onReject={confirmCancelDeal}
                        />
                      ),
                      payment: (
                        <DealPaymentPanel
                          deal={dealState.deal}
                          disabled={lockActionsRef.current}
                          onConfirm={confirmPayment}
                          onCancel={confirmCancelDeal}
                        />
                      ),
                      'wait-payment': (
                        <DealWaitPaymentPanel
                          deal={dealState.deal}
                          onConfirm={confirmSendCoinsNoAggreement}
                          disabled={lockActionsRef.current}
                        />
                      ),
                      'wait-coins': (
                        <DealWaitCoinsPanel
                          deal={dealState.deal}
                          disabled={lockActionsRef.current}
                          onOpenDispute={openDispute}
                        />
                      ),
                      'send-coins': (
                        <DealSendCoinsPanel
                          deal={dealState.deal}
                          disabled={lockActionsRef.current}
                          onSendCoins={confirmSendCoins}
                          onOpenDispute={openDispute}
                        />
                      ),
                      close: (
                        <DealClosePanel
                          deal={dealState.deal}
                          disabled={lockActionsRef.current}
                          onUserVote={voteUser}
                        />
                      ),
                      done: <DealDonePanel deal={dealState.deal} />,
                    }[dealState.currentStep]
                  }
                </DealContent>
              ) : (
                <DealContent>
                  <DealCanceledPanel deal={dealState.deal} />
                </DealContent>
              )}
            </PageRoot>
          ) : undefined}
        </MainColumn>

        <InfoColumn left={'30px'}>
          <PageRoot width={'100%'} minWidth={'300px'} minHeight={'65vh'}>
            {chatState.showChat ? (
              <ChatEx
                user={dealState.deal.partner.name}
                userStatus={dealState.deal.partner.status}
                height={'65vh'}
                captionPrefix={
                  dealState.deal.type === 'sale'
                    ? 'deal.chat.caption-buyer'
                    : 'deal.chat.caption-seller'
                }
                toolbar={
                  <CloseButtonBox size={'1.875rem'}>
                    <CloseButton
                      onClick={() => updateChatState({ showChat: false })}
                      hint={'common.close'}
                    />
                  </CloseButtonBox>
                }
                messages={chatState.messages}
                onSendMessage={sendMessage}
              />
            ) : (
              <>
                <PageCaption title={'deal.info'}>
                  <TextEx left={'.8125rem'} size={'1.125rem'} color={Defaults.mainColor}>
                    {dealState.deal.bidId ? `/l${dealState.deal.bidId}` : ''}
                  </TextEx>
                </PageCaption>
                <Hr />
                <DealInfoContent>
                  <DealInfoLine caption={'deal.rate'}>
                    <Amount
                      amount={dealState.deal.rate}
                      currency={dealState.deal.subCurrency}
                    />
                  </DealInfoLine>
                  <DealInfoLine caption={'deals.payment-method'}>
                    <PaymentMethodBox paymentMethod={dealState.deal.paymentMethod} />
                  </DealInfoLine>
                  <DealInfoLine
                    caption={
                      dealState.deal.type === 'sale' ? 'deals.buyer' : 'deals.seller'
                    }>
                    <UserLink user={dealState.deal.partner.name} top={'2px'} />
                    <UserDealsRating
                      left={'1vh'}
                      size={pageFontSize}
                      deals={dealState.deal.partner.deals}
                      rating={dealState.deal.partner.rating}
                    />
                  </DealInfoLine>
                  <DealInfoLine caption={'user-profile.reviews'}>
                    <UserReviews
                      likes={dealState.deal.partner.likes}
                      size={pageFontSize}
                      dislikes={dealState.deal.partner.dislikes}
                    />
                  </DealInfoLine>
                  <DealInfoLine caption={'user-profile.verification'}>
                    <CheckboxEx
                      checked={dealState.deal.partner.verified}
                      size={pageFontSize}
                    />
                  </DealInfoLine>
                  <DealInfoLine
                    caption={'user-profile.duration'}
                    value={dealState.deal.partner.duration}
                  />
                  <DealInfoLine
                    caption={'deal.limits'}
                    value={formatString(
                      t('deal.limits-mask'),
                      formatNumber(dealState.deal.limitMin),
                      formatNumber(dealState.deal.limitMax),
                      dealState.deal.subCurrency,
                    )}
                  />
                  <TradeConditionBox>
                    {dealState.deal.tradeCondition ? (
                      <DealText weight={300}>
                        <i>{dealState.deal.tradeCondition}</i>
                      </DealText>
                    ) : undefined}
                  </TradeConditionBox>
                </DealInfoContent>
                <Hr />
                <PageActions>
                  <Column width={'100%'} center>
                    <ActionButton
                      caption={'deal.chat.action-open'}
                      width={'100%'}
                      swapColors
                      img={<TelegramIcon size={'2rem'} fill={Defaults.mainColor} />}
                      onClick={openChat}>
                      &nbsp;
                      {!!chatState.newMessages?.length && (
                        <RoundBox size={'1.2rem'} fontSize={'.8rem'}>
                          {chatState.newMessages.length}
                        </RoundBox>
                      )}
                    </ActionButton>
                    <ComplainButton
                      caption={'deal.complain-conditions'}
                      top={'.75rem'}
                      onClick={complainConditions}
                    />
                  </Column>
                </PageActions>
              </>
            )}
          </PageRoot>
        </InfoColumn>
      </ContentRow>
      <WrongSubAmountDialog
        open={dialogState.showWrongSubAmount}
        onClose={() => updateDialogState({ showWrongSubAmount: false })}
        min={dealState.deal.limitMin}
        max={dealState.deal.limitMax}
        currency={dealState.deal.subCurrency}
      />
      <ConfirmOpenDialog
        open={dialogState.showConfirmOpen}
        onConfirm={openDeal}
        onCancel={() => updateDialogState({ showConfirmOpen: false })}
        type={dealState.deal.type}
        amount={dealState.amount}
        subAmount={dealState.subAmount}
        rate={dealState.deal.rate}
        currency={dealState.deal.currency}
        subCurrency={dealState.deal.subCurrency}
      />
      <ConfirmCancelDialog
        open={dialogState.showConfirmCancel}
        dealId={dealState.deal.id}
        onConfirm={cancelDeal}
        onCancel={() => updateDialogState({ showConfirmCancel: false })}
      />
      <ConfirmPaymentDialog
        open={dialogState.showConfirmPayment}
        subAmount={dealState.deal.subAmount}
        subCurrency={dealState.deal.subCurrency}
        user={dealState.deal.partner.name}
        paymentMethod={dealState.deal.paymentMethod}
        onConfirm={confirmPayment2}
        onCancel={() => updateDialogState({ showConfirmPayment: false })}
      />
      <ConfirmSendCoinsDialog
        open={dialogState.showConfirmSendCoins}
        deal={dealState.deal}
        onConfirm={sendCoins}
        onCancel={() => updateDialogState({ showConfirmSendCoins: false })}
      />
      <ConfirmSendCoinsDialog
        open={dialogState.showConfirmSendCoinsNoAgreement}
        deal={dealState.deal}
        noAgreement={true}
        onConfirm={sendNoAgreement}
        onCancel={() => updateDialogState({ showConfirmSendCoinsNoAgreement: false })}
      />
      <ComplainDialog
        open={dialogState.showComplainDialog}
        onClose={() => updateDialogState({ showComplainDialog: false })}
      />
      <SetRequisiteDialog
        open={dialogState.showSetRequisiteDialog}
        onClose={() => updateDialogState({ showSetRequisiteDialog: false })}
        onSend={sendRequisiteUpdate}
      />
    </ContentRoot>
  );
};

export default PageDealFunctional;
